// @flow
import React from 'react';
import gql from 'graphql-tag';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import faMobileAlt from '@fortawesome/fontawesome-free-solid/faMobileAlt';

import styles from './AssignRecurrenceAvailableOperatorsModal.module.scss';
import Table from '@src/components/Table';
import Button from '@src/components/Button';
import { FormattedDate } from '@src/components/i18n';
import RecurrenceCalendar from '@src/components/RecurrenceCalendar';
import Icon from '@src/components/Icon';

import type { Weekday } from '@src/types/utils';

export type Recurrence = {
  id: string,
  estimated_hours: number,
  hour: number,
  minutes: number,
  status: 'active' | 'inactive',
  weekdays: Array<Weekday>,
  office?: {
    id: string,
    name?: string,
  },
};

export type Operator = {
  id: string,
  first_name: string,
  last_name: string,
  created_at: string,
  recurrences: Array<Recurrence>,
  phone?: string,
};

type OperatorWithHoursPerWeek = Operator & { hours_per_week: number };

type AssignRecurrenceAvailableOperatorsModalProps = {
  loading: boolean,
  recurrence?: Recurrence,
  operators: Array<OperatorWithHoursPerWeek>,
  assign: (id: string, operator_id: string) => void,
  submitting: boolean,
};

export default function AssignRecurrenceAvailableOperatorsModal(
  props: AssignRecurrenceAvailableOperatorsModalProps
) {
  const { recurrence, operators, assign, loading, submitting } = props;

  if (loading) {
    return (
      <FormattedMessage
        id="AssignRecurrenceAvailableOperatorsModal.loading"
        defaultMessage="Buscando disponibilidad..."
        description="AssignRecurrenceAvailableOperatorsModal loading"
      />
    );
  }

  const recurrenceHeader = recurrence && (
    <Table className={cx(styles.table)}>
      <thead>
        <tr>
          <th colSpan="4">
            <FormattedMessage
              id="AssignRecurrenceAvailableOperatorsModal.recurrenceToAssign"
              defaultMessage="Recurrencia por asignar"
              description="AssignRecurrenceAvailableOperatorsModal recurrenceToAssign"
            />
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <th scope="row">
            <FormattedMessage
              id="AssignRecurrenceAvailableOperatorsModal.recurrence_id"
              defaultMessage="ID"
              description="AssignRecurrenceAvailableOperatorsModal recurrenc_id"
            />
          </th>

          <td>{recurrence.id}</td>

          <th scope="row">
            <FormattedMessage
              id="AssignRecurrenceAvailableOperatorsModal.calendar"
              defaultMessage="Horario"
              description="AssignRecurrenceAvailableOperatorsModal calendar"
            />
          </th>

          <td>
            <RecurrenceCalendar key={recurrence.id} recurrence={recurrence} />
          </td>
        </tr>
      </tbody>
    </Table>
  );

  return (
    <div>
      <div className="row">
        <div className="col-12">
          <h4>
            <FormattedMessage
              id="AssignRecurrenceAvailableOperatorsModal.title"
              defaultMessage="Asignar operador disponible"
              description="AssignRecurrenceAvailableOperatorsModal title"
            />
          </h4>
        </div>
      </div>

      <div className="row">{recurrenceHeader}</div>

      <div className="row">
        <div className="col-12">
          <Table className={cx('table-striped', styles.table)}>
            <thead>
              <tr>
                <th>
                  <FormattedMessage
                    id="AssignRecurrenceAvailableOperatorsModal.operator_id"
                    defaultMessage="ID"
                    description="AssignRecurrenceAvailableOperatorsModal operator_id"
                  />
                </th>

                <th>
                  <FormattedMessage
                    id="AssignRecurrenceAvailableOperatorsModal.name"
                    defaultMessage="Nombre"
                    description="AssignRecurrenceAvailableOperatorsModal name"
                  />
                </th>

                <th>
                  <FormattedMessage
                    id="AssignRecurrenceAvailableOperatorsModal.created_at"
                    defaultMessage="Fecha de registro"
                    description="AssignRecurrenceAvailableOperatorsModal created_at"
                  />
                </th>

                <th>
                  <FormattedMessage
                    id="AssignRecurrenceAvailableOperatorsModal.recurrences"
                    defaultMessage="Recurrencias"
                    description="AssignRecurrenceAvailableOperatorsModal recurrences"
                  />
                </th>

                <th>
                  <FormattedMessage
                    id="AssignRecurrenceAvailableOperatorsModal.hours_per_week"
                    defaultMessage="Horas p/semana"
                    description="AssignRecurrenceAvailableOperatorsModal hours_per_week"
                  />
                </th>

                <th />
              </tr>
            </thead>

            <tbody>
              {operators.map(operator => {
                const recurrences = operator.recurrences
                  ? operator.recurrences.filter(
                      recurrence => recurrence.status === 'active'
                    )
                  : [];
                return (
                  <tr key={operator.id}>
                    <td>{operator.id}</td>

                    <td>
                      <Link to={`/operator/${operator.id}`} target="_blank">
                        {`${operator.first_name || ''} ${operator.last_name ||
                          ''}`}
                      </Link>
                      <br />
                      <Icon icon={faMobileAlt} /> {`${operator.phone || '-'}`}
                    </td>

                    <td>
                      <FormattedDate value={operator.created_at} />
                    </td>

                    <td>
                      {recurrences.map(recurrence => (
                        <RecurrenceCalendar
                          key={recurrence.id}
                          recurrence={recurrence}
                        />
                      ))}
                    </td>

                    <td>{operator.hours_per_week || 0}</td>

                    <td>
                      <Button
                        btnStyle="info"
                        size="sm"
                        // $FlowIgnore
                        onClick={() => assign(recurrence.id, operator.id)}
                        disabled={submitting}
                      >
                        <FormattedMessage
                          id="AssignRecurrenceAvailableOperatorsModal.assign"
                          defaultMessage="Asignar"
                          description="AssignRecurrenceAvailableOperatorsModal assign"
                        />
                      </Button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  );
}

AssignRecurrenceAvailableOperatorsModal.fragments = {
  operators: gql`
    fragment AvailableOperatorAttributes on Operator {
      id
      first_name
      last_name
      created_at
      phone
      recurrences {
        id
        estimated_hours
        hour
        minutes
        status
        weekdays
        office {
          id
          name
        }
      }
    }
  `,
};
