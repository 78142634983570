// @flow
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ApolloProvider } from 'react-apollo';
import { ConnectedRouter } from 'react-router-redux';

import './styles/vendor/bootstrap.scss';
import './styles/vendor/visjs.scss';
import './styles/vendor/toastr.scss';
import './styles/vendor/datepicker.scss';
import './styles/vendor/reactSelect.scss';
import * as serviceWorker from './ServiceWorker';
import store, { history } from './redux/store';
import client from './graphql/client';
import { register as registerErrorReporter } from '@src/lib/errorReporter';
import App from './components/App';
import AppErrorBoundary from './AppErrorBoundary';

registerErrorReporter();

function render(Component) {
  // According to https://www.apollographql.com/docs/react/basics/setup.html#ApolloProvider
  // ApolloProvider should pass the store, but this issue documents
  // that it is not currently implemented: https://github.com/apollographql/react-apollo/issues/1360
  // in the future react-redux Provider can be removed.
  ReactDOM.render(
    <AppErrorBoundary>
      <ApolloProvider client={client}>
        <Provider store={store}>
          <ConnectedRouter history={history}>
            <Component />
          </ConnectedRouter>
        </Provider>
      </ApolloProvider>
    </AppErrorBoundary>, //$FlowIgnore
    document.getElementById('root')
  );
}

render(App);

if (process.env.NODE_ENV === 'production') {
  serviceWorker.unregister();
}

// $FlowIgnore
if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('./components/App', () => {
    const NextApp = require('./components/App').default;
    render(NextApp);
  });
}
