// @flow
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { reduxForm } from 'redux-form';
import compose from 'lodash/flowRight';

import messages from './messages';
import Button from '@src/components/Button';
import Form from '@src/components/Form';
import FormGroupInputField from '@src/components/FormGroupInputField';
import { isRequired } from '@src/utils/validation';

import type { IntlShape } from 'react-intl';

type CreateServiceNoShowCorrectionModalProps = {
  ...IntlShape,
  serviceNoShowId: string,
  operatorFirstName: string,
  handleSubmit: () => void,
  closeModal: () => void,
};

export function CreateServiceNoShowCorrectionModal(
  props: CreateServiceNoShowCorrectionModalProps
) {
  const {
    handleSubmit,
    operatorFirstName,
    closeModal,
    intl: { formatMessage },
  } = props;

  return (
    <Form className="row justify-content-center" onSubmit={handleSubmit}>
      <div className="col-sm-10">
        <h3>
          <FormattedMessage
            id="CreateServiceNoShowCorrectionModal.title"
            defaultMessage={
              'Cancelar no asistencia de ' +
              operatorFirstName +
              ' y asignar al servicio'
            }
            description="CreateServiceNoShowCorrectionModal title"
          />
        </h3>
      </div>
      <div className="col-sm-10">
        <FormGroupInputField
          name="comment"
          label={formatMessage(messages.manualno_showCommentLabel)}
          placeholder={formatMessage(messages.manualno_showCommentPlaceholder)}
          validate={[isRequired]}
        />

        <div className="modal-footer">
          <Button type="submit" btnStyle="primary" onClick={handleSubmit}>
            <FormattedMessage
              id="CreateServiceNoShowCorrectionModal.createButton"
              defaultMessage="Cancelar no asistencia"
              description="CreateServiceNoShowCorrectionModal createButton"
            />
          </Button>

          <Button btnStyle="light" size="sm" onClick={closeModal}>
            <FormattedMessage
              id="AssignServiceAvailableOperatorsModal.closeButton"
              defaultMessage="Cerrar"
              description="AssignServiceAvailableOperatorsModal closeButton"
            />
          </Button>
        </div>
      </div>
    </Form>
  );
}

export default compose(
  injectIntl,
  reduxForm({
    form: 'createServiceNoShowCorrectionForm',
  })
)(CreateServiceNoShowCorrectionModal);
