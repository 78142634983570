// @flow
import * as React from 'react';
import cx from 'classnames';

type FormProps = {
  className?: string,
  children?: React.Node,
  onSubmit?: any => void,
};

export default function Form(props: FormProps) {
  return (
    <form className={cx(props.className)} onSubmit={props.onSubmit}>
      {props.children}
    </form>
  );
}
