// @flow
import React from 'react';
import cx from 'classnames';

import styles from './PageTitle.module.scss';

import type { Node } from 'react';

type PageTitleProps = {
  className?: string,
  children: Node,
};

export default function PageTitle(props: PageTitleProps) {
  const { className, children } = props;

  return <h1 className={cx(className, styles.text)}>{children}</h1>;
}
