// @flow
import { connect } from 'react-redux';

import { close as closeModal } from '@src/redux/modules/modal';

const state = state => state;
const actions = {
  closeModal,
};

export default connect(state, actions);
