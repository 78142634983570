// @flow
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { reduxForm } from 'redux-form';
import compose from 'lodash/flowRight';

import { Link } from 'react-router-dom';
import messages from './messages';
import withInitialValues from './withInitialValues';
import Button from '@src/components/Button';
import Form from '@src/components/Form';
import FormGroupInputField from '@src/components/FormGroupInputField';
import FormGroupSelectField from '@src/components/FormGroupSelectField';
import { isRequired } from '@src/utils/validation';
import CheckboxField from '@src/components/CheckboxField';

import type { IntlShape } from 'react-intl';
import { NO_SHOW_TYPES } from '@src/constants/service';
import styles from './UpdateServiceNoShowModal.module.scss';

type UpdateServiceNoShowModalProps = {
  ...IntlShape,
  closeModal: () => void,
  handleSubmit: () => void,
};

export function UpdateServiceNoShowModal(props: UpdateServiceNoShowModalProps) {
  const {
    serviceNoShow,
    handleSubmit,
    closeModal,
    intl: { formatMessage },
  } = props;

  return (
    <Form className="row justify-content-center" onSubmit={handleSubmit}>
      <div className="col-sm-10">
        <h3>
          <FormattedMessage
            id="UpdateServiceNoShowModal.title"
            defaultMessage="Editar No asistencia de "
            description="UpdateServiceNoShowModal title"
            values={{ id: serviceNoShow.id }}
          />
          <Link to={`/operator/${serviceNoShow.operator.id}`} target="_blank">
            {`${serviceNoShow.operator.first_name || ''} ${serviceNoShow
              .operator.last_name || ''}`}
          </Link>
        </h3>
      </div>

      <div className="col-sm-10">
        <FormGroupSelectField
          name="no_show_type"
          label="No asistió o asistirá por:"
          options={NO_SHOW_TYPES}
          validate={[isRequired]}
        />

        <CheckboxField
          className={styles.deductOperator}
          name="deduct_operator"
          label={
            'Deducir este servicio de la paga de ' +
            serviceNoShow.operator.first_name
          }
        />

        <FormGroupInputField
          name="comment"
          label={formatMessage(messages.manualno_showCommentLabel)}
          placeholder={formatMessage(messages.manualno_showCommentPlaceholder)}
        />

        <div className="modal-footer">
          <Button type="submit" btnStyle="primary" onClick={handleSubmit}>
            <FormattedMessage
              id="UpdateServiceNoShowModal.updateButton"
              defaultMessage="Guardar no asistencia"
              description="UpdateServiceNoShowModal updateButton"
            />
          </Button>

          <Button btnStyle="light" size="sm" onClick={closeModal}>
            <FormattedMessage
              id="AssignServiceAvailableOperatorsModal.closeButton"
              defaultMessage="Cerrar"
              description="AssignServiceAvailableOperatorsModal closeButton"
            />
          </Button>
        </div>
      </div>
    </Form>
  );
}

export default compose(
  injectIntl,
  withInitialValues,
  reduxForm({
    form: 'UpdateServiceNoShowForm',
  })
)(UpdateServiceNoShowModal);
