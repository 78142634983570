// @flow
import React from 'react';
import cx from 'classnames';

import styles from './FormGroup.module.scss';
import Label from '@src/components/Label';

import type { Node } from 'react';

export type FormGroupProps = {
  className?: string,
  children?: Node,
  label?: *,
  labelClassName?: string,
  error?: string,
};

export default function FormGroup(props: FormGroupProps) {
  const { className, children, label, labelClassName, error, ...rest } = props;

  return (
    <div className={cx('form-group', className, styles.container)}>
      {!!label && (
        <Label {...rest} className={labelClassName}>
          {label}
        </Label>
      )}

      {children}

      {error && (
        <div className={cx('form-text', 'invalid-feedback')}>{error}</div>
      )}
    </div>
  );
}
