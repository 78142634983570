// @flow
import React from 'react';
import compose from 'lodash/flowRight';

import redux from './redux';
import graphql from './graphql';
import MonthlyCleaningInvoicingModal from './MonthlyCleaningInvoicingModal';

import type { Order } from './MonthlyCleaningInvoicingModal';

type MonthlyCleaningInvoicingModalContainerProps = {
  data: {
    loading: boolean,
    orders?: {
      edges: Array<{
        node: Order,
      }>,
    },
  },
  closeModal: () => void,
  onSubmit: () => void,
};

export class MonthlyCleaningInvoicingModalContainer extends React.Component<
  MonthlyCleaningInvoicingModalContainerProps
> {
  getOrders() {
    const { data: { orders } } = this.props;

    if (!orders) {
      return [];
    }

    return orders.edges.map(edge => edge.node);
  }

  render() {
    const { closeModal, onSubmit } = this.props;
    const orders = this.getOrders();
    return (
      <MonthlyCleaningInvoicingModal
        orders={orders}
        closeModal={closeModal}
        onSubmit={onSubmit}
      />
    );
  }
}

export default compose(redux, graphql)(MonthlyCleaningInvoicingModalContainer);
