// @flow
import React from 'react';

import CreateExtraServiceModal from './CreateExtraServiceModal';

type CreateExtraServiceModalContainerProps = {
  handleSubmit: () => void,
};

export default function CreateExtraServiceModalContainer(
  props: CreateExtraServiceModalContainerProps
) {
  return <CreateExtraServiceModal onSubmit={props.handleSubmit} />;
}
