// @flow
import React, { Component } from 'react';
import cx from 'classnames';
import faTimes from '@fortawesome/fontawesome-free-solid/faTimes';

import styles from './Modal.module.scss';
import Icon from '@src/components/Icon';

import type { Node } from 'react';

const ESCAPE_KEY = 'Escape';

type ModalProps = {
  closeModal: () => void,
  closeable?: boolean,
  children: Node,
};

export default class Modal extends Component<ModalProps, *> {
  container: ?HTMLDivElement;
  handleOverlayOnClick: (e: SyntheticEvent<*>) => void;
  handleKeyDown: (e: KeyboardEvent) => void;

  constructor(props: ModalProps) {
    super(props);
    this.handleOverlayOnClick = this.handleOverlayOnClick.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  componentDidMount() {
    if (this.props.closeable) {
      this.addKeyDownListener();
    }
  }

  componentWillUnmount() {
    this.removeKeyDownListener();
  }

  addKeyDownListener() {
    window.document.addEventListener('keydown', this.handleKeyDown);
  }

  removeKeyDownListener() {
    window.document.removeEventListener('keydown', this.handleKeyDown);
  }

  handleKeyDown(e: KeyboardEvent) {
    if (this.props.closeable && e.key === ESCAPE_KEY) {
      this.props.closeModal();
    }
  }

  handleOverlayOnClick(e: SyntheticEvent<*>) {
    const clickedInsideContainer =
      this.container && this.container.contains(e.currentTarget);

    if (!this.props.closeable || clickedInsideContainer) {
      return;
    }

    this.props.closeModal();
  }

  handleContainerOnClick(e: SyntheticEvent<*>) {
    e.stopPropagation();
  }

  render() {
    return (
      <div
        id="modal"
        className={styles.overlay}
        onClick={this.handleOverlayOnClick}
      >
        <div
          className={cx('container', styles.container)}
          ref={container => {
            this.container = container;
          }}
          onClick={this.handleContainerOnClick}
        >
          <div className={styles.body}>
            {this.props.children}

            {this.props.closeable && (
              <button
                className={styles.closeButton}
                onClick={this.props.closeModal}
              >
                <Icon icon={faTimes} />
              </button>
            )}
          </div>
        </div>
      </div>
    );
  }
}
