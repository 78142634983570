// @flow
import React from 'react';
import compose from 'lodash/flowRight';

import redux from './redux';
import Button from '@src/components/Button';

import type { ThemeColor } from '@src/types/bootstrap';

type ConfirmationModalProps = {
  title: string,
  text: string,
  confirmText?: string,
  cancelText?: string,
  payload?: any,
  confirmBtnStyle?: ThemeColor,
  cancelBtnStyle?: ThemeColor,
  onConfirm: (payload?: any) => void,
  onCancel?: (payload?: any) => void,
  closeModal: () => void,
};

export function ConfirmationModal(props: ConfirmationModalProps) {
  const {
    title,
    text,
    confirmText = 'Continuar',
    cancelText = 'Cancelar',
    payload,
    confirmBtnStyle = 'danger',
    cancelBtnStyle = 'light',
    onConfirm,
    onCancel,
    closeModal,
  } = props;

  const _onCancel = () => {
    if (onCancel) {
      onCancel(payload);
    }
    closeModal();
  };

  const _onConfirm = () => {
    onConfirm(payload);
    closeModal();
  };

  return (
    <div>
      <div className="modal-header">
        <h3 className="modal-title">{title}</h3>
      </div>
      <div className="modal-body">
        <p>{text}</p>
      </div>
      <div className="modal-footer">
        <Button btnStyle={cancelBtnStyle} onClick={() => _onCancel()}>
          {cancelText}
        </Button>
        <Button btnStyle={confirmBtnStyle} onClick={() => _onConfirm()}>
          {confirmText}
        </Button>
      </div>
    </div>
  );
}

export default compose(redux)(ConfirmationModal);
