// @flow

import type { SelectOption } from '@src/components/Select';
import type { Weekday } from '@src/types/utils';

import { map } from 'lodash';

type Offices = {
  edges: Array<{
    node: {
      id: string,
      name?: string,
      company: {
        id: string,
        name: string,
      },
    },
  }>,
};

export function optionsForPriceListItems(priceListItems: {
  id: string,
  price_per_unit: number,
  product: {
    title: string,
  },
}): Array<SelectOption> {
  if (!priceListItems) {
    return [];
  }

  return map(priceListItems, priceListItem => ({
    text: `${priceListItem.product.title} - $${priceListItem.price_per_unit /
      100}`,
    value: `${priceListItem.id}`,
  }));
}

export function optionsForOffices(offices?: Offices): Array<SelectOption> {
  if (!offices) {
    return [];
  }

  return offices.edges.map(({ node }) => ({
    text: `[${node.id}] ${node.name || '--'} (${node.company.name})`,
    value: `${node.id}`,
  }));
}

type Companies = {
  edges: Array<{
    node: {
      id: string,
      name?: string,
    },
  }>,
};

export function optionsForCompanies(
  companies?: Companies
): Array<SelectOption> {
  if (!companies) {
    return [];
  }

  return companies.edges.map(({ node }) => ({
    text: `${node.name || '--'}`,
    value: `${node.id}`,
  }));
}

type OperatorsGraph = {
  edges: Array<{
    node: {
      id: string,
      first_name?: string,
      last_name?: string,
    },
  }>,
};

export type Recurrence = {
  id: string,
  estimated_hours: number,
  hour: number,
  minutes: number,
  status: 'active' | 'inactive',
  weekdays: Array<Weekday>,
  office?: {
    id: string,
    name?: string,
  },
};

type Operators = Array<{
  id: string,
  first_name: string,
  last_name: string,
  created_at: string,
  hours_per_week: number,
  phone?: string,
  recurrences: Array<Recurrence>,
}>;

export function optionsForOperatorsGraph(
  operators?: OperatorsGraph
): Array<SelectOption> {
  if (!operators) {
    return [];
  }

  return operators.edges.map(({ node }) => ({
    text: `(${node.id}) ${node.first_name || '--'} ${node.last_name || '--'}`,
    value: `${node.id}`,
  }));
}

export function optionsForOperators(
  operators?: Operators
): Array<SelectOption> {
  if (!operators) {
    return [];
  }

  return operators.map(function(operator) {
    return {
      text: `(${operator.id}) ${operator.first_name ||
        '--'} ${operator.last_name ||
        '--'} -- Hrs. p/semana: ${operator.hours_per_week || 0}`,
      value: `${operator.id}`,
    };
  });
}

type CleaningSubscriptions = {
  edges: Array<{
    node: {
      id: string,
      office: {
        id: string,
        name: string,
        company: {
          id: string,
          name: string,
        },
      },
    },
  }>,
};
export function optionsForCleaningSubscriptions(
  cleaning_subscriptions?: CleaningSubscriptions
): Array<SelectOption> {
  if (!cleaning_subscriptions) {
    return [];
  }

  return cleaning_subscriptions.edges.map(({ node }) => ({
    text: `Suscripción #${node.id} de ${node.office.name} (${
      node.office.company.name
    })`,
    value: `${node.id}`,
  }));
}
