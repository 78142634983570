// @flow
import React from 'react';
import cx from 'classnames';

import styles from './TimestampSummary.module.scss';
import { FormattedDate } from '@src/components/i18n';
import Badge from '@src/components/Badge';

type TimestampProps = {
  value: string,
  topValue?: 'date' | 'time' | 'weekday',
};

export default function TimestampSummary(props: TimestampProps) {
  const { value, topValue = 'date' } = props;

  const dateProps = {
    day: 'numeric',
    month: '2-digit',
    year: 'numeric',
  };

  const weekdayProps = {
    weekday: 'short',
  };

  const timeProps = {
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
  };

  let topComponentProps, bottomLeftComponentProps, bottomRightComponentProps;

  switch (topValue) {
    case 'time':
      topComponentProps = timeProps;
      bottomLeftComponentProps = weekdayProps;
      bottomRightComponentProps = dateProps;
      break;
    case 'weekday':
      topComponentProps = weekdayProps;
      bottomLeftComponentProps = dateProps;
      bottomRightComponentProps = timeProps;
      break;
    default:
      topComponentProps = dateProps;
      bottomLeftComponentProps = weekdayProps;
      bottomRightComponentProps = timeProps;
      break;
  }

  return (
    <div className={cx(styles.container)}>
      <FormattedDate value={value} {...topComponentProps} />
      <div className={cx(styles.badges)}>
        <Badge badgeStyle="info" className={cx(styles.badge)}>
          <FormattedDate value={value} {...bottomLeftComponentProps} />
        </Badge>

        <Badge badgeStyle="secondary">
          <FormattedDate value={value} {...bottomRightComponentProps} />
        </Badge>
      </div>
    </div>
  );
}
