// @flow
import React from 'react';
import compose from 'lodash/flowRight';

import InvoicesCreatedModal from './InvoicesCreatedModal';
import redux from './redux';

import type { Invoice } from '@src/components/InvoiceableInvoices';

type InvoicesCreatedModalContainerProps = {
  invoices: Array<Invoice>,
  closeModal: () => void,
};

export function InvoicesCreatedModalContainer(
  props: InvoicesCreatedModalContainerProps
) {
  return (
    <InvoicesCreatedModal
      closeModal={props.closeModal}
      invoices={props.invoices}
    />
  );
}

export default compose(redux)(InvoicesCreatedModalContainer);
