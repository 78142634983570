// @flow
import Storage from 'local-storage-fallback';

import { AUTH_TOKEN as AUTH_TOKEN_KEY } from '@src/constants/storageKeys';

export function get(): string {
  return Storage.getItem(AUTH_TOKEN_KEY);
}

export function set(token: string): void {
  return Storage.setItem(AUTH_TOKEN_KEY, token);
}

export function remove(): void {
  return Storage.removeItem(AUTH_TOKEN_KEY);
}

export function loggedIn(): boolean {
  return !!get();
}
