// @flow
import { defineMessages } from 'react-intl';

const messages = defineMessages({
  paymentAccounts: {
    id: 'CreateInvoicePaymentModal.paymentAccounts',
    defaultMessage: 'Cuenta de Pago',
    description: 'CreateInvoicePaymentModal PaymentAccounts',
  },
  amountLabel: {
    id: 'CreateInvoicePaymentModal.amountLabel',
    defaultMessage: 'Monto',
    description: 'CreateInvoicePaymentModal amount label',
  },
  amountPlaceholder: {
    id: 'CreateInvoicePaymentModal.amountPlaceholder',
    defaultMessage: '1999.99',
    description: 'CreateInvoicePaymentModal amount placeholder',
  },
  paidAtLabel: {
    id: 'CreateInvoicePaymentModal.paidAtLabel',
    defaultMessage: 'Fecha de pago',
    description: 'CreateInvoicePaymentModal paid_at label',
  },
  referenceLabel: {
    id: 'CreateInvoicePaymentModal.referenceLabel',
    defaultMessage: 'Referencia',
    description: 'CreateInvoicePaymentModal reference label',
  },
  referencePlaceholder: {
    id: 'CreateInvoicePaymentModal.referencePlaceholder',
    defaultMessage: '1234567890',
    description: 'CreateInvoicePaymentModal reference placeholder',
  },
  descriptionLabel: {
    id: 'CreateInvoicePaymentModal.descriptionLabel',
    defaultMessage: 'Descripción',
    description: 'CreateInvoicePaymentModal description label',
  },
  descriptionPlaceholder: {
    id: 'CreateInvoicePaymentModal.descriptionPlaceholder',
    defaultMessage: 'Depósito por transferencia electrónica...',
    description: 'CreateInvoicePaymentModal description placeholder',
  },
});

export default messages;
