// @flow
import React from 'react';

import UpdateServiceModal from './UpdateServiceModal';

import type { Service } from '@src/pages/resources/service/view/ViewServiceDetails';

type UpdateServiceModalContainerProps = {
  handleSubmit: () => void,
  service: Service,
};

export default function UpdateServiceModalContainer(
  props: UpdateServiceModalContainerProps
) {
  return (
    <UpdateServiceModal onSubmit={props.handleSubmit} service={props.service} />
  );
}
