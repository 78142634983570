// @flow
import React from 'react';

import DeactivateOperatorModal from './DeactivateOperatorModal';

import type { Operator } from '@src/pages/resources/operator/view/attributes';

type DeactivateOperatorModalContainerProps = {
  operator: Operator,
  handleSubmit: () => void,
};

export default function DeactivateOperatorModalContainer(
  props: DeactivateOperatorModalContainerProps
) {
  return (
    <DeactivateOperatorModal
      operator={props.operator}
      onSubmit={props.handleSubmit}
    />
  );
}
