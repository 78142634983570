// @flow
import withProps from 'recompose/withProps';

export default withProps((props: *) => {
  if (!props.service) {
    return {};
  }

  const { service } = props;

  return {
    initialValues: {
      operator: service.operator
        ? `${service.operator.first_name} ${service.operator.last_name}`
        : '',
      check_time: service.datetime,
    },
  };
});
