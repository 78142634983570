// @flow
import React from 'react';
import cx from 'classnames';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';

import styles from './Icon.module.scss';

type IconProps = {
  className?: string,
  onClick?: () => void,
  icon: *,
};

export default function Icon(props: IconProps) {
  const { className, onClick, icon, ...rest } = props;

  const finalClassName = cx(onClick && styles.clickable, className);

  return (
    <FontAwesomeIcon
      className={finalClassName}
      onClick={onClick}
      icon={icon}
      {...rest}
    />
  );
}
