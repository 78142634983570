// @flow

type InputMeta = {
  active: boolean,
  invalid: boolean,
  touched: boolean,
};

export default function isInputInvalid(meta: InputMeta) {
  return meta && meta.touched && meta.invalid && !meta.active;
}
