// @flow
import React from 'react';
import moment from 'moment-timezone';
import { FormattedMessage } from 'react-intl';
import cx from 'classnames';

import styles from './RecurrenceCalendar.module.scss';
import WeekdayCalendar from '@src/components/WeekdayCalendar';
import { FormattedDate } from '@src/components/i18n';
import OldAdminLink from '@src/components/OldAdminLink';

import type { Weekday } from '@src/types/utils';

type Recurrence = {
  id: string,
  estimated_hours: number,
  hour: number,
  minutes: number,
  weekdays: Array<Weekday>,
  office?: {
    id: string,
    name?: string,
  },
};

type RecurrencesProps = {
  recurrence: Recurrence,
};

export default function RecurrenceCalendar(props: RecurrencesProps) {
  const { recurrence } = props;

  const buildHour = (hours: number, minutes: number, duration: number) => {
    const timeFrom = moment({ hours, minutes });
    const timeTo = moment({ hours, minutes }).add(duration, 'hours');
    return {
      from: timeFrom,
      to: timeTo,
    };
  };

  const hours = buildHour(
    recurrence.hour,
    recurrence.minutes,
    recurrence.estimated_hours
  );

  return (
    <div className={cx(styles.container)} key={recurrence.id}>
      <WeekdayCalendar weekdays={recurrence.weekdays} />
      <div>
        <FormattedDate value={hours.from} hour="numeric" minute="numeric" />{' '}
        <FormattedMessage
          id="Recurrences.to"
          defaultMessage="a"
          description="AssignRecurrenceAvailableOperatorsModal to"
        />{' '}
        <FormattedDate value={hours.to} hour="numeric" minute="numeric" />{' '}
        <FormattedMessage
          id="Recurrences.at"
          defaultMessage="en"
          description="AssignRecurrenceAvailableOperatorsModal at"
        />{' '}
        <OldAdminLink
          to={`/office/${recurrence.office ? recurrence.office.id : ''}`}
        >
          {`${(recurrence.office && recurrence.office.name) || ''}`}
        </OldAdminLink>
      </div>
    </div>
  );
}
