// @flow
import React from 'react';

import CreateServiceNoShowModal from './CreateServiceNoShowModal';

import type { Service } from '@src/pages/resources/service/view/ViewServiceDetails';

type CreateServiceNoShowModalContainerProps = {
  service: Service,
  handleSubmit: () => void,
  closeModal: () => void,
};

export default function CreateServiceNoShowModalContainer(
  props: CreateServiceNoShowModalContainerProps
) {
  return (
    <CreateServiceNoShowModal
      service={props.service}
      onSubmit={props.handleSubmit}
      closeModal={props.closeModal}
    />
  );
}
