// @flow
import React from 'react';
import cx from 'classnames';

import type { Node } from 'react';
import type { ThemeColor } from '@src/types/bootstrap';

type BadgeProps = {
  className?: string,
  badgeStyle?: ThemeColor,
  children: Node,
};

export default function Badge(props: BadgeProps) {
  const { className, badgeStyle, children, ...rest } = props;

  const finalClassName = cx(
    'badge',
    badgeStyle && `badge-${badgeStyle}`,
    className
  );

  return (
    <span className={finalClassName} {...rest}>
      {children}
    </span>
  );
}
