// @flow
import React, { Component } from 'react';
import compose from 'lodash/flowRight';
import { injectIntl } from 'react-intl';
import { toastr } from 'react-redux-toastr';

import NavBar from './NavBar';
import graphql from './graphql';
import { remove as logOut } from '@src/lib/auth';
import { resetUser } from '@src/lib/errorReporter';
import { replace as redirect } from '@src/utils/location';

import type { Admin } from '@src/types/models';
import type { IntlShape } from 'react-intl';
import type { Node } from 'react';

type Region = {
  id: string,
  slug: string,
  name: string,
};

type NavBarContainerProps = {
  ...IntlShape,
  data: {
    loading: boolean,
    current_admin?: Admin,
    regions?: {
      edges: {
        node: Region,
      },
    },
  },
  updateRegion: (region_id: string) => void,
  children?: Node,
};

export class NavBarContainer extends Component<NavBarContainerProps, *> {
  static defaultProps: NavBarContainerProps;
  handleLogout: Function;
  handleUpdateRegion: Function;

  constructor() {
    super();
    this.handleLogout = this.handleLogout.bind(this);
    this.handleUpdateRegion = this.handleUpdateRegion.bind(this);

    this.state = {
      loggingOut: false,
    };
  }

  handleLogout() {
    this.setState(() => ({ loggingOut: true }));
    logOut();
    resetUser();
    redirect('/login');
  }

  async handleUpdateRegion(region_id: string) {
    const { updateRegion } = this.props;

    try {
      await updateRegion(region_id);
      await window.location.reload();
    } catch (e) {
      toastr.error('Error', e.message);
    }
  }

  getRegions() {
    const { data: { regions } } = this.props;

    if (!regions) {
      return [];
    }

    return regions.edges.map(r => r.node);
  }

  render() {
    const { data: { loading, current_admin }, intl, children } = this.props;
    const admin = (!this.state.loggingOut && current_admin) || undefined;
    const regions = this.getRegions();

    return (
      <NavBar
        intl={intl}
        loading={loading}
        admin={admin}
        regions={regions}
        handleLogout={this.handleLogout}
        handleUpdateRegion={this.handleUpdateRegion}
        children={children}
      />
    );
  }
}

NavBarContainer.defaultProps = {
  data: {
    loading: false,
  },
};

export default compose(graphql, injectIntl)(NavBarContainer);
