// @flow
import React from 'react';
import cx from 'classnames';

import styles from './Logo.module.scss';
import logo from '@src/assets/img/tandem-logo.svg';
import Image from '@src/components/Image';

type LogoProps = {
  className?: string,
};

export default function Logo({ className }: LogoProps) {
  return (
    <Image
      className={cx(styles.logo, className)}
      src={logo}
      alt="Tandem Logo"
    />
  );
}
