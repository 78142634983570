// @flow
import React, { Component, Fragment } from 'react';
import { IntlProvider as OriginalIntlProvider } from 'react-intl';

import i18n, { I18N } from '@src/lib/i18n';

type IntlProviderProps = {
  locale: string,
  timeZone: string,
  children: *,
};

export default class IntlProvider extends Component<IntlProviderProps, *> {
  constructor(props: IntlProviderProps) {
    super(props);

    this.state = {
      configuringI18n: true,
    };
  }

  componentDidMount() {
    this.configureI18n();
  }

  componentDidUpdate(prevProps: IntlProviderProps) {
    if (
      this.props.locale !== prevProps.locale ||
      this.props.timeZone !== prevProps.timeZone
    ) {
      this.configureI18n();
    }
  }

  async configureI18n() {
    await this.loadLocaleData();
    await this.loadMessages();
    this.setTimeZone();

    this.setState(() => ({
      configuringI18n: false,
    }));
  }

  async loadLocaleData() {
    const { locale } = this.props;

    try {
      i18n.setLocale(locale);
      await i18n.loadLocaleData();
      return;
    } catch (e) {}

    try {
      const language = I18N.getLanguageFromLocale(locale);
      i18n.setLocale(language);
      await i18n.loadLocaleData();
      return;
    } catch (e) {}

    const language = i18n.getDefaultLocale();
    i18n.setLocale(language);
    await i18n.loadLocaleData();
  }

  async loadMessages() {
    const { locale } = this.props;

    try {
      return await i18n.loadMessages(locale);
    } catch (e) {}

    try {
      const language = I18N.getLanguageFromLocale(locale);
      return await i18n.loadMessages(language);
    } catch (e) {}
  }

  setTimeZone() {
    i18n.setTimeZone(this.props.timeZone);
  }

  render() {
    if (this.state.configuringI18n) {
      return null;
    }

    return (
      <OriginalIntlProvider
        locale={i18n.getLocale()}
        messages={i18n.getMessages()}
        defaultLocale={i18n.getDefaultLocale()}
        textComponent={Fragment}
      >
        {this.props.children}
      </OriginalIntlProvider>
    );
  }
}
