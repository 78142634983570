// @flow
import React from 'react';
import gql from 'graphql-tag';
import { FormattedMessage } from 'react-intl';
import compose from 'lodash/flowRight';
import { reduxForm } from 'redux-form';

import Button from '@src/components/Button';
import Form from '@src/components/Form';
import FormGroupSelectField from '@src/components/FormGroupSelectField';
import CheckboxField from '@src/components/CheckboxField';

import { isRequired } from '@src/utils/validation';
import { optionsForOperators } from '@src/utils/selectOptions';

import {
  NO_SHOW_TYPES,
  OPERATOR_COMPENSATION_TYPES,
} from '@src/constants/service';
import type { ServiceStatusEnum } from '@src/types/models';
import styles from './AssignServiceAvailableOperatorsModal.module.scss';

import type { Weekday } from '@src/types/utils';

export type Recurrence = {
  id: string,
  estimated_hours: number,
  hour: number,
  minutes: number,
  status: 'active' | 'inactive',
  weekdays: Array<Weekday>,
  office?: {
    id: string,
    name?: string,
  },
};

export type Operator = {
  id: string,
  first_name: string,
  last_name: string,
  created_at: string,
  recurrences: Array<Recurrence>,
  phone?: string,
};

type OperatorWithHoursPerWeek = Operator & { hours_per_week: number };

export type Service = {
  id: string,
  datetime: string,
  estimated_hours: number,
  status: ServiceStatusEnum,
  punctuality_value: 'late' | 'delayed' | 'unexpected' | 'on_time',
  punctuality_description: string,
  operator: Operator,
  created_at: string,
  office?: {
    id: string,
    name?: string,
  },
};

type AssignServiceAvailableOperatorsModalProps = {
  loading: boolean,
  service: Service,
  operators: Array<OperatorWithHoursPerWeek>,
  handleSubmit: () => void,
  closeModal: () => void,
};

export function AssignServiceAvailableOperatorsModal(
  props: AssignServiceAvailableOperatorsModalProps
) {
  const { service, operators, handleSubmit, closeModal, loading } = props;
  const officeName = `${(service.office && service.office.name) || '-'}`;

  if (loading) {
    return (
      <FormattedMessage
        id="AssignServiceAvailableOperatorsModal.loading"
        defaultMessage="Buscando disponibilidad..."
        description="AssignServiceAvailableOperatorsModal loading"
      />
    );
  }
  const operatorsOptions = optionsForOperators(operators);
  const hasOperator = !!service.operator;

  return (
    <Form className="row justify-content-center" onSubmit={handleSubmit}>
      <div className="col-sm-10">
        <h3>
          <FormattedMessage
            id="AssignServiceAvailableOperatorsModal.title"
            defaultMessage={
              'Asignar operador disponible a servicio ' +
              `${service.id}` +
              ' en ' +
              officeName
            }
            description="AssignServiceAvailableOperatorsModal title"
          />
        </h3>
      </div>
      <div className="col-sm-10">
        <FormGroupSelectField
          name="operator_id"
          label="operador"
          options={operatorsOptions}
          defaultText="Selecciona un operador:"
          validate={[isRequired]}
        />

        {hasOperator && (
          <FormGroupSelectField
            name="no_show_type"
            label={service.operator.first_name + ' no asistió o asisitirá por:'}
            options={NO_SHOW_TYPES}
            defaultText={NO_SHOW_TYPES[0].text}
            validate={[isRequired]}
          />
        )}

        {hasOperator && (
          <CheckboxField
            className={styles.deductOperator}
            name="deduct_operator"
            label={
              'Deducir este servicio de la paga de ' +
              service.operator.first_name
            }
          />
        )}

        <FormGroupSelectField
          name="operator_compensation_type"
          label="Paga el servicio al operador elegido:"
          options={OPERATOR_COMPENSATION_TYPES}
          defaultText={OPERATOR_COMPENSATION_TYPES[0].text}
          validate={[isRequired]}
        />

        <div className="modal-footer">
          <Button
            type="submit"
            btnStyle="info"
            size="sm"
            onClick={handleSubmit}
          >
            <FormattedMessage
              id="AssignServiceAvailableOperatorsModal.assignButton"
              defaultMessage="Asignar"
              description="AssignServiceAvailableOperatorsModal assignButton"
            />
          </Button>

          <Button btnStyle="light" size="sm" onClick={closeModal}>
            <FormattedMessage
              id="AssignServiceAvailableOperatorsModal.closeButton"
              defaultMessage="Cerrar"
              description="AssignServiceAvailableOperatorsModal closeButton"
            />
          </Button>
        </div>
      </div>
    </Form>
  );
}

export const fragments = {
  operators: gql`
    fragment AvailableOperatorAttributes on Operator {
      id
      first_name
      last_name
      created_at
      phone
      recurrences {
        id
        estimated_hours
        hour
        minutes
        status
        weekdays
        office {
          id
          name
        }
      }
    }
  `,
};

export default compose(
  reduxForm({
    form: 'AssingServiceForm',
  })
)(AssignServiceAvailableOperatorsModal);
