// @flow
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { reduxForm } from 'redux-form';
import compose from 'lodash/flowRight';

import messages from './messages';
import withInitialValues from './withInitialValues';
import Button from '@src/components/Button';
import Form from '@src/components/Form';
import FormGroupDatetimeField from '@src/components/FormGroupDatetimeField';
import FormGroupSelectField from '@src/components/FormGroupSelectField';
import { isRequired } from '@src/utils/validation';
import { EXTRA_SHIFT_DURATION_OPTIONS } from '@src/constants/cleaningSubscriptions';

import type { IntlShape } from 'react-intl';
import type { Service } from '@src/pages/resources/service/view/ViewServiceDetails';

type UpdateServiceModalProps = {
  ...IntlShape,
  service: Service,
  handleSubmit: () => void,
};

export function UpdateServiceModal(props: UpdateServiceModalProps) {
  const { handleSubmit, intl: { formatMessage }, service } = props;

  return (
    <Form className="row justify-content-center" onSubmit={handleSubmit}>
      <div className="col-sm-10">
        <h3>
          <FormattedMessage
            id="UpdateServiceModal.title"
            defaultMessage="Editar Servicio #{id}"
            description="UpdateServiceModal title"
            values={{
              id: service.id,
            }}
          />
        </h3>
      </div>
      <div className="col-sm-10">
        <FormGroupDatetimeField
          name="datetime"
          label={formatMessage(messages.datetimeLabel)}
          size="sm"
          validate={[isRequired]}
        />
        <FormGroupSelectField
          name="estimated_hours"
          size="sm"
          label={formatMessage(messages.estimatedHoursLabel)}
          options={EXTRA_SHIFT_DURATION_OPTIONS}
          defaultText={formatMessage(messages.estimatedHoursPlaceholder)}
          validate={[isRequired]}
        />
        <Button
          type="submit"
          size="sm"
          btnStyle="success"
          onClick={handleSubmit}
        >
          <FormattedMessage
            id="UpdateServiceModal.saveButton"
            defaultMessage="Guardar"
            description="UpdateServiceModal addButton"
          />
        </Button>
      </div>
    </Form>
  );
}

export default compose(
  injectIntl,
  withInitialValues,
  reduxForm({
    form: 'updateServiceForm',
  })
)(UpdateServiceModal);
