// @flow
import withProps from 'recompose/withProps';

export default withProps((props: *) => {
  if (!props.service) {
    return {};
  }

  const { service } = props;

  return {
    initialValues: {
      ...service,
      estimated_hours: service.estimated_hours.toString(),
    },
  };
});
