// @flow
import React from 'react';

import CreateServiceNoShowCorrectionModal from './CreateServiceNoShowCorrectionModal';

type CreateServiceNoShowCorrectionModalContainerProps = {
  serviceNoShowId: string,
  operatorFirstName: string,
  handleSubmit: () => void,
  closeModal: () => void,
};

export default function CreateServiceNoShowCorrectionModalContainer(
  props: CreateServiceNoShowCorrectionModalContainerProps
) {
  return (
    <CreateServiceNoShowCorrectionModal
      serviceNoShowId={props.serviceNoShowId}
      operatorFirstName={props.operatorFirstName}
      onSubmit={props.handleSubmit}
      closeModal={props.closeModal}
    />
  );
}
