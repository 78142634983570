// @flow
import React from 'react';

import AuthedRoute from '@src/components/router/AuthedRoute';

export function resourceName(path: string): ?string {
  const match = path.match(
    /^\.\/resources\/(.*)\/(list|create|view)\/index\.async\.js$/
  );
  return match && match[1];
}

export function resourceMethod(path: string): ?string {
  const match = path.match(
    /^\.\/resources\/(.*)\/(list|create|view)\/index\.async\.js$/
  );
  return match && match[2];
}

export function resourcePath(name: string, method: string) {
  switch (method) {
    case 'list':
      return {
        path: `/${name}`,
        exact: true,
      };
    case 'create':
      return {
        path: `/${name}/new`,
        exact: true,
      };
    case 'view':
      return {
        path: `/${name}/:${name}_id`,
        exact: false,
      };
    default:
      throw new Error(`Unexpected method: ${method}`);
  }
}

export default function makeRoutes() {
  // $FlowIgnore
  const resourcesContext = require.context(
    '@src/pages',
    true,
    /^\.\/resources\/(.*)\/(list|create|view)\/index\.async\.js$/
  );

  const routeDescriptors = resourcesContext.keys().map(resource => {
    const name = resourceName(resource);
    const method = resourceMethod(resource);

    if (!name || !method) {
      throw new Error(`Missing name or method in resource: ${resource}`);
    }

    const resPath = resourcePath(name, method);

    return {
      path: resPath.path,
      exact: resPath.exact,
      component: resourcesContext(resource).default,
    };
  });

  const routes = routeDescriptors.map(rD => (
    <AuthedRoute
      key={rD.path}
      exact={rD.exact}
      path={rD.path}
      component={rD.component}
    />
  ));

  return routes;
}
