// @flow
import { Component } from 'react';
import { withRouter } from 'react-router-dom';

import { pushGTM } from '@src/utils/analytics';

import type { ContextRouter } from 'react-router-dom';

type PageViewRecorderProps = ContextRouter & {};

export class PageViewRecorder extends Component<PageViewRecorderProps, *> {
  handleLocationChange: (location: *, action: *) => void;
  unlisten: void | (() => void);

  constructor(props: PageViewRecorderProps) {
    super(props);

    this.handleLocationChange = this.handleLocationChange.bind(this);
  }

  componentDidMount() {
    this.unlisten = this.props.history.listen(this.handleLocationChange);
  }

  componentWillUnmount() {
    if (this.unlisten) {
      this.unlisten();
    }
  }

  handleLocationChange() {
    pushGTM('TrackPageView');
  }

  render() {
    return null;
  }
}

export default withRouter(PageViewRecorder);
