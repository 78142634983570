// @flow
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { reduxForm } from 'redux-form';
import compose from 'lodash/flowRight';

import messages from './messages';
import Button from '@src/components/Button';
import Form from '@src/components/Form';
import FormGroupInputField from '@src/components/FormGroupInputField';
import FormGroupSelectField from '@src/components/FormGroupSelectField';
import { isRequired } from '@src/utils/validation';
import { DOWNTIME_REASON_OPTIONS } from '@src/constants/operators';

import type { IntlShape } from 'react-intl';
import type { Operator } from '@src/pages/resources/operator/view/attributes';

type DeactivateOperatorModalProps = {
  ...IntlShape,
  operator: Operator,
  handleSubmit: () => void,
};

const YES_NO_OPTIONS = [
  {
    text: 'Sí',
    value: 'true',
  },
  {
    text: 'No',
    value: 'false',
  },
];

export function DeactivateOperatorModal(props: DeactivateOperatorModalProps) {
  const { operator, handleSubmit, intl: { formatMessage } } = props;

  return (
    <Form onSubmit={handleSubmit}>
      <div className="modal-header">
        <h3 className="modal-title">
          <FormattedMessage
            id="DeactivateOperatorModal.title"
            defaultMessage="Dar de baja operador #{id}"
            description="DeactivateOperatorModal title"
            values={{ id: operator.id }}
          />
        </h3>
      </div>
      <div className="modal-body">
        <FormGroupSelectField
          name="reason"
          label={formatMessage(messages.reasonLabel)}
          options={DOWNTIME_REASON_OPTIONS}
          defaultText={formatMessage(messages.reasonPlaceholder)}
          validate={[isRequired]}
        />
        <FormGroupSelectField
          name="rehirable"
          label={formatMessage(messages.rehirableLabel)}
          options={YES_NO_OPTIONS}
          defaultText={formatMessage(messages.rehirablePlaceholder)}
          validate={[isRequired]}
        />
        <FormGroupInputField
          name="comment"
          type="text"
          label={formatMessage(messages.commentLabel)}
          placeholder={formatMessage(messages.commentPlaceholder)}
          validate={[isRequired]}
        />
      </div>
      <div className="modal-footer">
        <Button type="submit" btnStyle="danger" onClick={handleSubmit}>
          <FormattedMessage
            id="DeactivateOperatorModal.saveButton"
            defaultMessage="Dar de baja"
            description="DeactivateOperatorModal saveButton"
          />
        </Button>
      </div>
    </Form>
  );
}

export default compose(
  injectIntl,
  reduxForm({
    form: 'DeactivateOperatorForm',
  })
)(DeactivateOperatorModal);
