// @flow
import React, { Component } from 'react';
import cx from 'classnames';
import { default as ReactSelect } from 'react-select';

import styles from './Select.module.scss';

export type SelectOption = {
  text: string,
  value: string,
};

export type SelectProps = {
  className?: string,
  options: $ReadOnlyArray<SelectOption | string>,
  value?: string,
  defaultText?: string,
  size?: 'sm' | 'lg',
  isInvalid?: boolean,
  disabled?: boolean,
};

type WrappedSelectProps = SelectProps & {
  onChange: Function,
  onBlur: Function,
};

type OptionType = {
  label: string,
  value: string,
};

type OptionsType = Array<OptionType>;

type ValueType = OptionType | OptionsType | null | void;

export default class Select extends Component<WrappedSelectProps, *> {
  handleChange: Function;
  handleBlur: Function;

  constructor() {
    super();
    this.handleChange = this.handleChange.bind(this);
    this.handleBlur = this.handleBlur.bind(this);

    this.state = {
      portalTarget: document.getElementById('modal'),
    };
  }

  static getDerivedStateFromProps(props: any, state: any) {
    return {
      portalTarget: state.portalTarget || document.getElementById('root'),
    };
  }

  getOptions(): Array<OptionType> {
    const { options } = this.props;
    const list = options.map(option => {
      if (typeof option === 'string') {
        return {
          label: option,
          value: option,
        };
      }

      return {
        label: option.text,
        value: option.value,
      };
    });
    return list;
  }

  getValues(): ValueType {
    let selectedValues;
    const { value } = this.props;
    const options = this.getOptions();

    if (typeof value === 'string') {
      const values = value.split(',');
      if (values.length > 1) {
        selectedValues = options.filter(opt => values.includes(opt.value));
      } else {
        selectedValues = options.find(option => option.value === value);
      }
    } else {
      selectedValues = value;
    }

    return selectedValues;
  }

  handleChange(value: ValueType) {
    const { onChange } = this.props;
    let val;

    if (Array.isArray(value)) {
      const values = value.map(v => v.value);
      val = '';
      values.forEach((v, i, a) => {
        val += a.length === i + 1 ? `${v}` : `${v},`;
      });
    } else {
      val = (value && `${value.value}`) || '';
    }

    return onChange(val);
  }

  handleBlur(e: SyntheticEvent<*>) {
    e.preventDefault();
    return this.props.onBlur();
  }

  render() {
    const {
      className,
      value,
      options,
      defaultText = 'Selecciona...',
      size,
      isInvalid,
      disabled,
      onChange,
      onBlur,
      ...rest
    } = this.props;

    const optionsList = this.getOptions();
    const values = this.getValues();

    const finalClassName = cx(
      'form-control',
      'tandem-select',
      className,
      styles.tandemSelect,
      size && `form-control-${size}`,
      isInvalid && 'is-invalid'
    );

    return (
      <ReactSelect
        classNamePrefix="tandem-select"
        className={finalClassName}
        options={optionsList}
        placeholder={defaultText}
        isDisabled={disabled}
        value={values}
        menuPortalTarget={this.state.portalTarget}
        styles={{
          menuPortal: base => ({
            ...base,
            zIndex: 100,
          }),
        }}
        onChange={this.handleChange}
        onBlur={this.handleBlur}
        {...rest}
      />
    );
  }
}
