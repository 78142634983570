// @flow
import { defineMessages } from 'react-intl';

const messages = defineMessages({
  checkTypeLabel: {
    id: 'CreateServiceCheckModal.checkTypeLabel',
    defaultMessage: 'Tipo',
    description: 'Label for check_type field',
  },
  checkTypePlaceholder: {
    id: 'CreateServiceCheckModal.checkTypePlaceholder',
    defaultMessage: 'Selecciona un tipo de falta...',
    description: 'Placeholder for no_show_type field',
  },
  manualno_showCommentLabel: {
    id: 'CreateServiceCheckModal.manualno_showCommentLabel',
    defaultMessage: 'Comentario',
    description: 'Label for comment field',
  },
  manualno_showCommentPlaceholder: {
    id: 'CreateServiceCheckModal.manualno_showCommentPlaceholder',
    defaultMessage: '¿Qué sabes sobre la no asistencia?',
    description: 'Placeholder for comment field',
  },
  operatorLabel: {
    id: 'CreateServiceCheckModal.operatorLabel',
    defaultMessage: 'Operador',
    description: 'Label for operator field',
  },
});

export default messages;
