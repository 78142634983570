// @flow
import React from 'react';

import Page from '@src/components/Page';
import PageTitle from '@src/components/PageTitle';

type IndexProps = {};

function Index(props: IndexProps) {
  return (
    <Page title="Home">
      <PageTitle>Bienvenido</PageTitle>
    </Page>
  );
}

export default Index;
