import React, { Component } from 'react';
import cx from 'classnames';
import compose from 'lodash/flowRight';
import { Helmet } from 'react-helmet';
import ReduxToastr from 'react-redux-toastr';

import styles from './App.module.scss';
import graphql from './graphql';
import { setUser } from '@src/lib/errorReporter';
import { IntlProvider } from '@src/components/i18n';
import PageViewRecorder from '@src/components/PageViewRecorder';
import NavBar from '@src/components/NavBar';
import { Routes } from '@src/routes';
import Modal from '@src/components/Modal';

import type { Region } from '@src/types/models';

type AppProps = {
  data?: {
    loading: boolean,
    current_admin?: {
      id: string,
      email: string,
      region: Region,
    },
  },
};

export class App extends Component<AppProps> {
  componentWillReceiveProps(nextProps: NavBarContainerProps) {
    const { data } = this.props;

    if (data && !data.current_admin && nextProps.data.current_admin) {
      setUser(nextProps.data.current_admin.email);
    }
  }

  getLocaleFromRegionId(regionId?: string) {
    switch (regionId) {
      case '1':
      case '2':
        return 'es-MX';
      case '3':
        return 'es-PE';
      default:
        return 'es-MX';
    }
  }

  render() {
    const region =
      this.props.data &&
      this.props.data.current_admin &&
      this.props.data.current_admin.region;
    const locale = this.getLocaleFromRegionId(region && region.id);
    const timeZone = (region && region.time_zone) || 'America/Mexico_City';

    return (
      <IntlProvider locale={locale} timeZone={timeZone}>
        <div id="app" className={cx(styles.App)}>
          <PageViewRecorder />
          <Helmet defaultTitle="Tandem | Admin" titleTemplate="T-Admin | %s" />
          <NavBar>
            <Routes />
          </NavBar>
          <Modal />
          <ReduxToastr
            preventDuplicates
            position="top-center"
            transitionIn="fadeIn"
            transitionOut="fadeOut"
          />
        </div>
      </IntlProvider>
    );
  }
}

export default compose(graphql)(App);
