// @flow
import React, { Component } from 'react';
import compose from 'lodash/flowRight';

import AssignTaskOperatorsModal from './AssignTaskOperatorsModal';
import graphql from './graphql';
import { optionsForOperatorsGraph } from '@src/utils/selectOptions';

import type { Task } from './';

type AssignTaskOperatorsModalContainerProps = {
  data: {
    loading: boolean,
    task?: Task,
    operators?: {
      edges: Array<{
        node: {
          id: string,
          first_name?: string,
          last_name?: string,
        },
      }>,
    },
  },
  task_id: string,
  assign: (task_id: string, operator_id: string) => void,
  remove: (task_id: string, operator_id: string) => void,
};

export class AssignTaskOperatorsModalContainer extends Component<
  AssignTaskOperatorsModalContainerProps
> {
  handleSubmit: Function;

  constructor() {
    super();
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(data: *) {
    const { task_id } = this.props;
    this.props.assign(task_id, data.operator_id);
  }

  render() {
    const { remove, data: { loading, task, operators } } = this.props;
    const operatorOptions = optionsForOperatorsGraph(operators);

    return (
      <AssignTaskOperatorsModal
        loading={loading}
        task={task}
        operatorOptions={operatorOptions}
        onSubmit={this.handleSubmit}
        remove={remove}
      />
    );
  }
}

export default compose(graphql)(AssignTaskOperatorsModalContainer);
