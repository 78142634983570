// @flow
import { createStore, applyMiddleware, compose } from 'redux';
import { routerMiddleware } from 'react-router-redux';
import createHistory from 'history/createBrowserHistory';

import rootReducer from '@src/redux/modules/rootReducer';

export const history = createHistory();

const initialState = {};
const enhancers = [];
const middlewares = [routerMiddleware(history)];

const { devToolsExtension } = window;
if (typeof devToolsExtension === 'function') {
  enhancers.push(devToolsExtension());
}

const composedEnhancers = compose(
  applyMiddleware(...middlewares),
  ...enhancers
);

const store = createStore(rootReducer, initialState, composedEnhancers);

export default store;
