// @flow
import { graphql, compose } from 'react-apollo';
import gql from 'graphql-tag';

import MonthlyCleaningInvoicingModal from './MonthlyCleaningInvoicingModal';

const invoiceableOrders = graphql(
  gql`
    query InvoiceableOrders(
      $office_id: ID
      $closed_from: DateTime
      $closed_to: DateTime
    ) {
      orders(
        office_id: [$office_id]
        status: [CLOSED]
        invoice_status: [NOT_INVOICED]
        closed_from: $closed_from
        closed_to: $closed_to
      ) {
        edges {
          node {
            ...MonthlyCleaningInvoicingModalOrders
          }
        }
      }
    }
    ${MonthlyCleaningInvoicingModal.fragments.orders}
  `,
  {
    options: ({ office_id, closed_from, closed_to }) => {
      return {
        variables: {
          office_id,
          closed_from,
          closed_to,
        },
        fetchPolicy: 'network-only',
      };
    },
  }
);

export default compose(invoiceableOrders);
