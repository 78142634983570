// @flow
import React from 'react';
import cx from 'classnames';

import Badge from '@src/components/Badge';

type PillBadgeProps = {
  className?: string,
  badgeStyle?: *,
  children: *,
};

export default function PillBadge(props: PillBadgeProps) {
  const { className, children, badgeStyle } = props;

  const finalClassName = cx('badge-pill', className);

  return (
    <Badge className={finalClassName} badgeStyle={badgeStyle}>
      {children}
    </Badge>
  );
}
