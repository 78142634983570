// @flow
import React from 'react';

import CreateServiceCheckInModal from './CreateServiceCheckInModal';

import type { Service } from '@src/pages/resources/service/view/ViewServiceDetails';

type CreateServiceCheckInModalContainerProps = {
  service: Service,
  handleSubmit: () => void,
};

export default function CreateServiceCheckInModalContainer(
  props: CreateServiceCheckInModalContainerProps
) {
  return (
    <CreateServiceCheckInModal
      service={props.service}
      onSubmit={props.handleSubmit}
    />
  );
}
