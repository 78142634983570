// @flow
import { defineMessages } from 'react-intl';

const messages = defineMessages({
  estimatedHoursLabel: {
    id: 'CreateExtraServiceForm.estimatedHoursLabel',
    defaultMessage: 'Duración del servicio',
    description: 'Label for default estimated_hours field',
  },
  estimatedHoursPlaceholder: {
    id: 'CreateExtraServiceForm.estimatedHoursPlaceholder',
    defaultMessage: 'Selecciona una duración...',
    description: 'Placeholder for default estimated_hours field',
  },
  datetimeLabel: {
    id: 'UpdateServiceForm.datetimeLabel',
    defaultMessage: 'Horario',
    description: 'Label for default datetime field',
  },
});

export default messages;
