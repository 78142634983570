// @flow
import { defineMessages } from 'react-intl';

const messages = defineMessages({
  cancelationReasonLabel: {
    id: 'CancelServiceForm.cancelationReasonLabel',
    defaultMessage: 'Razón de cancelación',
    description: 'Label for cancelation reason field',
  },
});

export default messages;
