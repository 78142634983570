// @flow

export const STATUS_CREATED = 'CREATED';
export const STATUS_PENDING_APPROVAL = 'PENDING_APPROVAL';
export const STATUS_PENDING_ACCEPTANCE = 'PENDING_ACCEPTANCE';
export const STATUS_ACCEPTED = 'ACCEPTED';
export const STATUS_CLOSED = 'CLOSED';
export const STATUS_CANCELED = 'CANCELED';
export const STATUS_PARTIALLY_DELIVERED = 'PARTIALLY_DELIVERED';

export const STATUS_OPTIONS = [
  {
    text: 'Creada',
    value: STATUS_CREATED,
  },
  {
    text: 'Pendiente de aprobación',
    value: STATUS_PENDING_APPROVAL,
  },
  {
    text: 'Pendiente de aceptar',
    value: STATUS_PENDING_ACCEPTANCE,
  },
  {
    text: 'Aceptada',
    value: STATUS_ACCEPTED,
  },
  {
    text: 'Cerrada',
    value: STATUS_CLOSED,
  },
  {
    text: 'Cancelada',
    value: STATUS_CANCELED,
  },
  {
    text: 'Parcialmente entregada',
    value: STATUS_PARTIALLY_DELIVERED,
  },
];

export const SELECTABLE_STATUS_OPTIONS = [
  {
    text: 'Creada',
    value: STATUS_CREATED,
  },
  {
    text: 'Pendiente de aceptar',
    value: STATUS_PENDING_ACCEPTANCE,
  },
  {
    text: 'Aceptada',
    value: STATUS_ACCEPTED,
  },
  {
    text: 'Cerrada',
    value: STATUS_CLOSED,
  },
  {
    text: 'Cancelada',
    value: STATUS_CANCELED,
  },
  {
    text: 'Parcialmente entregada',
    value: STATUS_PARTIALLY_DELIVERED,
  },
];

export const PRODUCT_ORDER_TYPE = 'PRODUCT_ORDER';
export const SERVICE_ORDER_TYPE = 'SERVICE_ORDER';

export const ORDER_TYPES = [
  {
    text: 'Productos',
    value: PRODUCT_ORDER_TYPE,
  },
  {
    text: 'Servicios',
    value: SERVICE_ORDER_TYPE,
  },
];
