// @flow
import React from 'react';
import moment from 'moment-timezone';
import cx from 'classnames';

import styles from './WeekdayCalendar.module.scss';
import { FormattedDate } from '@src/components/i18n';
import PillBagde from '@src/components/PillBadge';
import weekdayToNumber from '@src/utils/weekdayToNumber';

import type { Weekday } from '@src/types/utils';

type WeekdayCalendarProps = {
  weekdays: Array<Weekday>,
};

const WEEKDAYS = [
  'MONDAY',
  'TUESDAY',
  'WEDNESDAY',
  'THURSDAY',
  'FRIDAY',
  'SATURDAY',
  'SUNDAY',
];

export default function WeekdayCalendar(props: WeekdayCalendarProps) {
  const { weekdays } = props;
  return (
    <div className={cx(styles.container)}>
      {WEEKDAYS.map(day => {
        const exists = weekdays.find(weekday => weekday.toUpperCase() === day);
        return (
          <PillBagde
            key={`${day}`}
            className={cx(styles.badge)}
            badgeStyle={exists ? 'success' : 'light'}
          >
            <FormattedDate
              value={moment().day(weekdayToNumber(day))}
              weekday="narrow"
            />
          </PillBagde>
        );
      })}
    </div>
  );
}
