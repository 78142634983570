// @flow
import { graphql, compose } from 'react-apollo';
import gql from 'graphql-tag';

import { AssignTaskOperatorsModal } from './AssignTaskOperatorsModal';

const taskOperatorsData = graphql(
  gql`
    query Task($task_id: ID!) {
      task(id: $task_id) {
        ...TaskAttributes
      }

      operators(
        status: ACTIVE
        sort_by: { field: FIRST_NAME, direction: ASC }
      ) {
        edges {
          node {
            ...TaskOperatorAttributes
          }
        }
      }
    }
    ${AssignTaskOperatorsModal.fragments.task}
    ${AssignTaskOperatorsModal.fragments.operators}
  `,
  {
    options: ({ task_id }) => {
      return {
        variables: {
          task_id,
        },
        fetchPolicy: 'network-only',
      };
    },
  }
);

export default compose(taskOperatorsData);
