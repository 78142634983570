// @flow
import * as React from 'react';
import cx from 'classnames';

import type { ThemeColor } from '@src/types/bootstrap';

type ButtonProps = {
  className?: string,
  btnStyle?: ThemeColor | 'link',
  type?: 'button' | 'submit' | 'reset',
  size?: 'sm' | 'lg',
  outline?: boolean,
  fill?: boolean,
  children?: React.Node,
  onClick?: () => void,
};

export default function Button(props: ButtonProps) {
  const {
    btnStyle = 'primary',
    size,
    outline,
    type = 'button',
    onClick,
    className,
    children,
    fill,
    ...rest
  } = props;

  const styleClassName = outline
    ? `btn-outline-${btnStyle}`
    : `btn-${btnStyle}`;
  const sizeClassName = size ? `btn-${size}` : null;
  const finalClassNames = cx(
    'btn',
    fill && 'btn-block',
    styleClassName,
    sizeClassName,
    className
  );

  return (
    <button className={finalClassNames} type={type} onClick={onClick} {...rest}>
      {children}
    </button>
  );
}
