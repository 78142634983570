// @flow
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { reduxForm } from 'redux-form';
import compose from 'lodash/flowRight';

import { Link } from 'react-router-dom';
import messages from './messages';
import withInitialValues from './withInitialValues';
import Button from '@src/components/Button';
import Form from '@src/components/Form';
import FormGroupInputField from '@src/components/FormGroupInputField';
import FormGroupSelectField from '@src/components/FormGroupSelectField';
import { isRequired } from '@src/utils/validation';
import CheckboxField from '@src/components/CheckboxField';

import type { IntlShape } from 'react-intl';
import type { Service } from '@src/pages/resources/service/view/ViewServiceDetails';
import { NO_SHOW_TYPES } from '@src/constants/service';
import styles from './CreateServiceNoShowModal.module.scss';

type CreateServiceNoShowModalProps = {
  ...IntlShape,
  service: Service,
  handleSubmit: () => void,
};

export function CreateServiceNoShowModal(props: CreateServiceNoShowModalProps) {
  const { service, handleSubmit, closeModal, intl: { formatMessage } } = props;
  const hasOperator = !!service.operator;

  return (
    <Form className="row justify-content-center" onSubmit={handleSubmit}>
      {hasOperator && (
        <div className="col-sm-10">
          <h3>
            <FormattedMessage
              id="CreateServiceNoShowModal.title"
              defaultMessage="No asistencia y quitar del servicio a "
              description="CreateServiceNoShowModal title"
              values={{ id: service.id }}
            />
            <Link to={`/operator/${service.operator.id}`} target="_blank">
              {`${service.operator.first_name || ''} ${service.operator
                .last_name || ''}`}
            </Link>
          </h3>
        </div>
      )}
      <div className="col-sm-10">
        <FormGroupSelectField
          name="no_show_type"
          label="No asistió o asistirá por:"
          options={NO_SHOW_TYPES}
          defaultText={NO_SHOW_TYPES[0].text}
          validate={[isRequired]}
        />

        <CheckboxField
          className={styles.deductOperator}
          name="deduct_operator"
          label={
            'Deducir este servicio de la paga de ' + service.operator.first_name
          }
        />

        <FormGroupInputField
          name="comment"
          label={formatMessage(messages.manualno_showCommentLabel)}
          placeholder={formatMessage(messages.manualno_showCommentPlaceholder)}
        />

        <div className="modal-footer">
          <Button type="submit" btnStyle="primary" onClick={handleSubmit}>
            <FormattedMessage
              id="CreateServiceNoShowModal.createButton"
              defaultMessage="Guardar no asistencia"
              description="CreateServiceNoShowModal createButton"
            />
          </Button>

          <Button btnStyle="light" size="sm" onClick={closeModal}>
            <FormattedMessage
              id="AssignServiceAvailableOperatorsModal.closeButton"
              defaultMessage="Cerrar"
              description="AssignServiceAvailableOperatorsModal closeButton"
            />
          </Button>
        </div>
      </div>
    </Form>
  );
}

export default compose(
  injectIntl,
  withInitialValues,
  reduxForm({
    form: 'createServiceNoShowForm',
  })
)(CreateServiceNoShowModal);
