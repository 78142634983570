// @flow

export const STARTING_TIME_OPTIONS = (() => {
  const options = [];
  for (let i = 0; i < 24; i += 1) {
    options.push({
      text: i < 10 ? `0${i}:00` : `${i}:00`,
      value: `${i}:00`,
    });
    options.push({
      text: i < 10 ? `0${i}:30` : `${i}:30`,
      value: `${i}:30`,
    });
  }

  // Push 23:59 as a valid hour
  options.push({ text: `23:59`, value: `23:59` });

  return options;
})();

export const SHIFT_DURATION_OPTIONS = [
  {
    text: '1 hr',
    value: '1',
  },
  {
    text: '2 hr',
    value: '2',
  },
  {
    text: '3 hr',
    value: '3',
  },
  {
    text: '4 hr',
    value: '4',
  },
  {
    text: '7 hr',
    value: '7',
  },
  {
    text: '8 hr',
    value: '8',
  },
  {
    text: '10 hr',
    value: '10',
  },
];

export const EXTRA_SHIFT_DURATION_OPTIONS = [
  {
    text: '1 hr',
    value: '1',
  },
  {
    text: '2 hr',
    value: '2',
  },
  {
    text: '3 hr',
    value: '3',
  },
  {
    text: '4 hr',
    value: '4',
  },
  {
    text: '5 hr',
    value: '5',
  },
  {
    text: '6 hr',
    value: '6',
  },
  {
    text: '7 hr',
    value: '7',
  },
  {
    text: '8 hr',
    value: '8',
  },
  {
    text: '9 hr',
    value: '9',
  },
  {
    text: '10 hr',
    value: '10',
  },
];

export const WEEKDAY_OPTIONS = [
  {
    label: 'Lu',
    value: 'MONDAY',
  },
  {
    label: 'Ma',
    value: 'TUESDAY',
  },
  {
    label: 'Mi',
    value: 'WEDNESDAY',
  },
  {
    label: 'Ju',
    value: 'THURSDAY',
  },
  {
    label: 'Vi',
    value: 'FRIDAY',
  },
  {
    label: 'Sa',
    value: 'SATURDAY',
  },
  {
    label: 'Do',
    value: 'SUNDAY',
  },
];

export const MORNING_SHIFT_CUTOFF_HOUR = 12;
export const AFTERNOON_SHIFT_CUTOFF_HOUR = 19;
export const EVENING_SHIFT_CUTOFF_HOUR = 6;
