// @flow
import { connect } from 'react-redux';

import { close } from '@src/redux/modules/modal';

const select = state => ({
  data: state.modal,
});

const actions = {
  close,
};

export default connect(select, actions);
