// @flow
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import compose from 'lodash/flowRight';

import NavBarFragments from './NavBarFragments';
import { loggedIn } from '@src/lib/auth';

const withData = graphql(
  gql`
    query NavBarData {
      current_admin {
        ...AdminProfile
      }

      regions {
        edges {
          node {
            ...AdminRegions
          }
        }
      }
    }
    ${NavBarFragments.adminProfile}
    ${NavBarFragments.regions}
  `,
  {
    skip: !loggedIn(),
  }
);

const updateRegion = graphql(
  gql`
    mutation UpdateAdminRegion($region_id: ID!) {
      update_admin_region(region_id: $region_id) {
        ...AdminProfile
      }
    }
    ${NavBarFragments.adminProfile}
  `,
  {
    name: 'updateRegionMutation',
    // $FlowIgnore
    props: ({ updateRegionMutation }) => ({
      updateRegion: (region_id: string) => {
        return updateRegionMutation({
          variables: { region_id },
        });
      },
    }),
  }
);

export default compose(withData, updateRegion);
