// @flow
import React from 'react';
import DatePicker from 'react-datepicker';
import moment from 'moment-timezone';
import cx from 'classnames';
import { Field } from 'redux-form';

import styles from './FormGroupDatetimeField.module.scss';
import FormGroup from '@src/components/FormGroup';
import isInputInvalid from '@src/utils/isInputInvalid';

import type { FieldProps } from 'redux-form';
import type { Node as ReactNode } from 'react';

type FormGroupDatetimeFieldProps = {
  name: string,
  label?: *,
  placeholder?: string,
  disabled?: boolean,
  dateFormat?: string,
  timeFormat?: string,
  timeIntervals?: number,
  className?: string,
  size?: string,
  showTimeSelect?: boolean,
  showTimeSelectOnly?: boolean,
  locale?: string,
};

type RFFormGroupDatePickerProps = FormGroupDatetimeFieldProps & {
  ...FieldProps,
};

export function RFFormGroupDatePicker(
  props: RFFormGroupDatePickerProps
): ReactNode {
  const {
    className,
    label,
    size = '',
    meta,
    input = {},
    placeholder,
    disabled,
    dateFormat = 'LLL',
    timeFormat = 'HH:mm',
    timeIntervals = 10,
    showTimeSelect = true,
    showTimeSelectOnly = false,
    locale = 'es',
    ...rest
  } = props;

  const error = (meta && isInputInvalid(meta) && meta.error) || '';
  const htmlFor = `${(meta && meta.form) || ''}-${input.name || Math.random()}`;
  const datePickerClassName = cx(
    'form-control',
    styles.container,
    size && `form-control-${size}`,
    !!error && 'is-invalid'
  );

  return (
    <FormGroup
      className={className}
      label={label}
      error={error}
      htmlFor={htmlFor}
    >
      <div className={(!!error && 'invalid-datepicker') || ''}>
        <DatePicker
          id={htmlFor}
          autoComplete="off"
          className={datePickerClassName}
          placeholder={placeholder}
          disabled={disabled}
          dateFormat={dateFormat}
          timeFormat={timeFormat}
          timeIntervals={timeIntervals}
          showTimeSelect={showTimeSelect}
          showTimeSelectOnly={showTimeSelectOnly}
          locale={locale}
          selected={input.value ? moment(input.value) : null}
          onChange={input.onChange}
          onBlur={() => input.onBlur()}
          {...rest}
        />
      </div>
    </FormGroup>
  );
}

export default function FormGroupDatetimeField(
  props: FormGroupDatetimeFieldProps
) {
  const normalize = value =>
    value && value.toISOString ? value.seconds(0).toISOString() : value;

  return (
    <Field {...props} component={RFFormGroupDatePicker} normalize={normalize} />
  );
}
