// @flow
import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import { reducer as formReducer } from 'redux-form';
import { reducer as toastrReducer } from 'react-redux-toastr';

import modal from './modal';

export default combineReducers({
  form: formReducer,
  router: routerReducer,
  toastr: toastrReducer,
  modal,
});
