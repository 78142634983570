// @flow
import gql from 'graphql-tag';

export default {
  adminProfile: gql`
    fragment AdminProfile on Admin {
      id
      email
      region {
        id
        name
        time_zone
      }
    }
  `,
  regions: gql`
    fragment AdminRegions on Region {
      id
      slug
      name
    }
  `,
};
