// @flow
import { graphql, compose } from 'react-apollo';
import gql from 'graphql-tag';

import { fragments as AssignServiceAvailableOperatorsModal } from './AssignServiceAvailableOperatorsModal';

const availableOperatorsData = graphql(
  gql`
    query AvailableOperators($service_id: ID, $operator_role: operator_role) {
      operators_by_availability(service_id: $service_id, role: $operator_role) {
        edges {
          node {
            ...AvailableOperatorAttributes
          }
        }
      }
    }
    ${AssignServiceAvailableOperatorsModal.operators}
  `,
  {
    options: ({ service_id, operator_role }) => {
      return {
        variables: {
          service_id,
          operator_role,
        },
        fetchPolicy: 'network-only',
      };
    },
  }
);

export default compose(availableOperatorsData);
