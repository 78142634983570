// @flow
import React from 'react';
import faBuilding from '@fortawesome/fontawesome-free-solid/faBuilding';

import styles from './OfficeInfo.module.scss';
import OldAdminLink from '@src/components/OldAdminLink';
import Icon from '@src/components/Icon';

import type { CompanySettingsInvoicingEnum } from '@src/types/models';

type OfficeInfoProps = {
  office: {
    id: string,
    name?: string,
    company: {
      id: string,
      name: string,
      invoicing: CompanySettingsInvoicingEnum,
    },
  },
};

function OfficeInfo(props: OfficeInfoProps) {
  const { office } = props;

  return (
    <div>
      <OldAdminLink to={`/office/${office.id}`}>
        {office.name || '-'}
      </OldAdminLink>
      <br />
      <OldAdminLink
        to={`/company/${office.company.id}`}
        className={styles.companyLink}
      >
        <Icon icon={faBuilding} /> {office.company.name}
        <br />
      </OldAdminLink>
    </div>
  );
}

export default OfficeInfo;
