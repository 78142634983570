// @flow
import React from 'react';

import CancelServiceModal from './CancelServiceModal';

import type { Service } from '@src/pages/resources/service/view/ViewServiceDetails';

type CancelServiceModalContainerProps = {
  handleSubmit: () => void,
  service: Service,
};

export default function CancelServiceModalContainer(
  props: CancelServiceModalContainerProps
) {
  return (
    <CancelServiceModal onSubmit={props.handleSubmit} service={props.service} />
  );
}
