// @flow
import { graphql, compose } from 'react-apollo';
import gql from 'graphql-tag';

import { CreateInvoicePaymentModal } from './CreateInvoicePaymentModal';

const paymentAccountsData = graphql(
  gql`
    query PaymentAccounts {
      payment_accounts {
        edges {
          node {
            ...CreateInvoicePaymentAccounts
          }
        }
      }
    }
    ${CreateInvoicePaymentModal.fragments.payment_accounts}
  `,
  {
    options: () => {
      return {
        variables: {},
        fetchPolicy: 'network-only',
      };
    },
  }
);

export default compose(paymentAccountsData);
