// @flow
import React from 'react';
import gql from 'graphql-tag';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { reduxForm } from 'redux-form';
import cx from 'classnames';
import compose from 'lodash/flowRight';
import faMobileAlt from '@fortawesome/fontawesome-free-solid/faMobileAlt';
import faPlusCircle from '@fortawesome/fontawesome-free-solid/faPlusCircle';
import faTrash from '@fortawesome/fontawesome-free-solid/faTrash';

import styles from './AssignTaskOperatorsModal.module.scss';
import messages from './messages';
import Table from '@src/components/Table';
import Button from '@src/components/Button';
import Form from '@src/components/Form';
import FormGroupSelectField from '@src/components/FormGroupSelectField';
import Icon from '@src/components/Icon';
import PillBadge from '@src/components/PillBadge';
import { isRequired } from '@src/utils/validation';

import type { SelectOption } from '@src/components/Select';
import type { IntlShape } from 'react-intl';

export type Operator = {
  id: string,
  first_name?: string,
  last_name?: string,
  phone?: string,
};

export type Task = {
  id: string,
  title: string,
  operators: Array<Operator>,
  office: {
    id: string,
    name: string,
  },
};

type AssignTaskOperatorsModalProps = {
  ...IntlShape,
  loading: boolean,
  task?: Task,
  operatorOptions: Array<SelectOption>,
  handleSubmit: () => void,
  remove: (task_id: string, operator_id: string) => void,
};

export function AssignTaskOperatorsModal(props: AssignTaskOperatorsModalProps) {
  const {
    task,
    operatorOptions,
    handleSubmit,
    remove,
    loading,
    intl: { formatMessage },
  } = props;

  if (loading) {
    return (
      <FormattedMessage
        id="AssignTaskOperatorsModal.loading"
        defaultMessage="Cargando..."
        description="AssignTaskOperatorsModal loading"
      />
    );
  }

  const taskHeader = task && (
    <Table className={styles.table}>
      <thead>
        <tr>
          <th colSpan="8">
            <FormattedMessage
              id="AssignTaskOperatorsModal.taskToAssign"
              defaultMessage="Solicitud por asignar"
              description="AssignTaskOperatorsModal taskToAssign"
            />
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <th scope="row">
            <FormattedMessage
              id="AssignTaskOperatorsModal.task.id"
              defaultMessage="ID"
              description="AssignTaskOperatorsModal task id"
            />
          </th>

          <td>{task.id}</td>

          <th scope="row">
            <FormattedMessage
              id="AssignTaskOperatorsModal.task.office"
              defaultMessage="Ubicación"
              description="AssignTaskOperatorsModal task offixe"
            />
          </th>

          <td>{task.office.name}</td>

          <th scope="row">
            <FormattedMessage
              id="AssignTaskOperatorsModal.task.title"
              defaultMessage="Título"
              description="AssignTaskOperatorsModal task title"
            />
          </th>

          <td>
            <p style={{ margin: 0 }}>{task.title}</p>
          </td>

          <th>
            <FormattedMessage
              id="AssignTaskOperatorsModal.task.finished"
              defaultMessage="Status"
              description="AssignTaskOperatorsModal task finished"
            />
          </th>

          <td>
            {task.finished ? (
              <PillBadge badgeStyle="success">
                <FormattedMessage
                  id="AssignTaskOperatorsModal.task.finished.true"
                  defaultMessage="Completada"
                  description="Attribute name"
                />
              </PillBadge>
            ) : (
              <PillBadge badgeStyle="danger">
                <FormattedMessage
                  id="AssignTaskOperatorsModal.task.finished.false"
                  defaultMessage="Sin completar"
                  description="Attribute name"
                />
              </PillBadge>
            )}
          </td>
        </tr>
      </tbody>
    </Table>
  );

  return (
    <div>
      <div className={cx('row', styles.header)}>{taskHeader}</div>

      <div className="row">
        <div className="col-12">
          <h3 className="text-center">
            <FormattedMessage
              id="AssignTaskOperatorsModal.title"
              defaultMessage="Operadores"
              description="AssignTaskOperatorsModal title"
            />
          </h3>
        </div>
      </div>

      <div className="row justify-content-center">
        <div className="col-8">
          <Form className="row" onSubmit={handleSubmit}>
            <div className="col-sm-9">
              <FormGroupSelectField
                name="operator_id"
                size="sm"
                options={operatorOptions}
                defaultText={formatMessage(messages.operatorPlaceholder)}
                validate={[isRequired]}
              />
            </div>
            <div className="col-sm-3">
              <Button type="submit" size="sm" btnStyle="primary">
                <Icon icon={faPlusCircle} />{' '}
                <FormattedMessage
                  id="AssignTaskOperatorsModal.addButton"
                  defaultMessage="Agregar"
                  description="AssignTaskOperatorsModal addButton"
                />
              </Button>
            </div>
          </Form>
          <Table className={cx('table-striped', styles.table)}>
            <thead>
              <tr>
                <th>
                  <FormattedMessage
                    id="AssignTaskOperatorsModal.operator_id"
                    defaultMessage="ID"
                    description="AssignTaskOperatorsModal operator_id"
                  />
                </th>

                <th>
                  <FormattedMessage
                    id="AssignTaskOperatorsModal.name"
                    defaultMessage="Nombre"
                    description="AssignTaskOperatorsModal name"
                  />
                </th>

                <th />
              </tr>
            </thead>

            <tbody>
              {task &&
                task.operators.map(operator => {
                  const { first_name = '', last_name = '' } = operator;
                  return (
                    <tr key={operator.id}>
                      <td>{operator.id}</td>

                      <td>
                        <Link to={`/operator/${operator.id}`} target="_blank">
                          {`${first_name} ${last_name}`}
                        </Link>
                        <br />
                        <Icon icon={faMobileAlt} /> {`${operator.phone || '-'}`}
                      </td>

                      <td>
                        <Button
                          btnStyle="danger"
                          size="sm"
                          onClick={() => remove(task.id, operator.id)}
                        >
                          <Icon icon={faTrash} />{' '}
                          <FormattedMessage
                            id="AssignTaskOperatorsModal.remove"
                            defaultMessage="Eliminar"
                            description="AssignTaskOperatorsModal remove"
                          />
                        </Button>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  );
}

AssignTaskOperatorsModal.fragments = {
  task: gql`
    fragment TaskAttributes on Task {
      id
      title
      finished
      office {
        id
        name
      }
      operators {
        id
        first_name
        last_name
        phone
      }
    }
  `,
  operators: gql`
    fragment TaskOperatorAttributes on Operator {
      id
      first_name
      last_name
    }
  `,
};

export default compose(
  injectIntl,
  reduxForm({
    form: 'assignTaskOperators',
  })
)(AssignTaskOperatorsModal);
