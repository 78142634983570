// @flow
import React, { Component } from 'react';
import compose from 'lodash/flowRight';

import AssignRecurrenceAvailableOperatorsModal from './AssignRecurrenceAvailableOperatorsModal';
import graphql from './graphql';

import type { Operator, Recurrence } from './';

type OperatorWithHoursPerWeek = Operator & { hours_per_week: number };

type AssignRecurrenceAvailableOperatorsModalContainerProps = {
  data: {
    loading: boolean,
    operators_by_availability?: {
      edges: [
        {
          node: Operator,
        },
      ],
    },
  },
  recurrence?: Recurrence,
  assign: (id: string, operator_id: string) => void,
};

type AssignRecurrenceAvailableOperatorsModalContainerState = {
  submitting: boolean,
};

export class AssignRecurrenceAvailableOperatorsModalContainer extends Component<
  AssignRecurrenceAvailableOperatorsModalContainerProps,
  AssignRecurrenceAvailableOperatorsModalContainerState
> {
  handleAssign: Function;

  constructor() {
    super();

    this.handleAssign = this.handleAssign.bind(this);

    this.state = {
      submitting: false,
    };
  }

  getOperators(): Array<OperatorWithHoursPerWeek> {
    const { data: { loading, operators_by_availability } } = this.props;

    if (loading || !operators_by_availability) {
      return [];
    }

    return operators_by_availability.edges.map(edge => {
      const { node } = edge;

      const recurrences = node.recurrences.filter(
        recurrence => recurrence.status === 'active'
      );

      const hours_per_week = recurrences.reduce((sum, recurrence) => {
        const weekdays = recurrence.weekdays.length;
        return sum + weekdays * recurrence.estimated_hours;
      }, 0);

      return {
        ...node,
        hours_per_week,
      };
    });
  }

  async handleAssign(id: string, operator_id: string): Promise<*> {
    const { assign } = this.props;

    this.setState(() => ({ submitting: true }));

    await assign(id, operator_id);

    this.setState(() => ({ submitting: false }));
  }

  render() {
    const { recurrence, data: { loading } } = this.props;
    const { submitting } = this.state;
    const operators = this.getOperators();

    return (
      <AssignRecurrenceAvailableOperatorsModal
        loading={loading}
        submitting={submitting}
        recurrence={recurrence}
        operators={operators}
        assign={this.handleAssign}
      />
    );
  }
}

export default compose(graphql)(
  AssignRecurrenceAvailableOperatorsModalContainer
);
