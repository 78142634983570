// @flow
import { graphql, compose } from 'react-apollo';
import gql from 'graphql-tag';

import AssignRecurrenceAvailableOperatorsModal from './AssignRecurrenceAvailableOperatorsModal';

const availableOperatorsData = graphql(
  gql`
    query AvailableOperators(
      $recurrence_id: ID
      $operator_role: operator_role
    ) {
      operators_by_availability(
        recurrence_id: $recurrence_id
        role: $operator_role
      ) {
        edges {
          node {
            ...AvailableOperatorAttributes
          }
        }
      }
    }
    ${AssignRecurrenceAvailableOperatorsModal.fragments.operators}
  `,
  {
    options: ({ recurrence_id, operator_role }) => {
      return {
        variables: {
          recurrence_id,
          operator_role,
        },
        fetchPolicy: 'network-only',
      };
    },
  }
);

export default compose(availableOperatorsData);
