// @flow
import { defineMessages } from 'react-intl';

const messages = defineMessages({
  manualCheckReasonLabel: {
    id: 'CreateServiceCheckInModal.manualCheckReasonLabel',
    defaultMessage: 'Razón del check manual',
    description: 'Label for manual_check_reason field',
  },
  manualCheckReasonPlaceholder: {
    id: 'CreateServiceCheckInModal.manualCheckReasonPlaceholder',
    defaultMessage: 'Selecciona una razón ...',
    description: 'Placeholder for manual_check_reason field',
  },
  checkTimeLabel: {
    id: 'CreateServiceCheckInModal.checkTimeLabel',
    defaultMessage: 'Hora',
    description: 'Label for check_time field',
  },
  operatorLabel: {
    id: 'CreateServiceCheckInModal.operatorLabel',
    defaultMessage: 'Operador',
    description: 'Label for operator field',
  },
});

export default messages;
