// @flow
import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import { loggedIn } from '@src/lib/auth';

import type { ComponentType } from 'react';
import type { ContextRouter } from 'react-router';

type UnauthedRouteProps = {
  component: ComponentType<*>,
  redirectTo?: string,
};

export function UnauthedRoute(props: UnauthedRouteProps) {
  const { component: Component, redirectTo, ...rest } = props;

  return (
    <Route
      {...rest}
      render={(props: ContextRouter) => {
        if (!loggedIn()) {
          return <Component {...props} />;
        }

        return <Redirect to={redirectTo || '/'} />;
      }}
    />
  );
}

export default UnauthedRoute;
