import React from 'react';
import { Switch, Route } from 'react-router-dom';

import NotFound from '@src/pages/404';
import Index from '@src/pages/Index';
import Login from '@src/pages/Login';
import TimeLine from '@src/pages/TimeLine';
import AuthedRoute from '@src/components/router/AuthedRoute';
import UnauthedRoute from '@src/components/router/UnauthedRoute';
import makeResourceRoutes from './resourceRoutes';

export const Routes = () => (
  <Switch>
    <AuthedRoute path="/" exact component={Index} />
    <AuthedRoute path="/timeline" exact component={TimeLine} />
    <UnauthedRoute path="/login" exact component={Login} />
    {makeResourceRoutes()}
    <Route component={NotFound} />
  </Switch>
);

export default Routes;
