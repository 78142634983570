// @flow
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { reduxForm } from 'redux-form';
import compose from 'lodash/flowRight';

import messages from './messages';
import Button from '@src/components/Button';
import Form from '@src/components/Form';
import FormGroupSelectField from '@src/components/FormGroupSelectField';
import FormGroupDatetimeField from '@src/components/FormGroupDatetimeField';
import FormGroupInputField from '@src/components/FormGroupInputField';
import { isRequired } from '@src/utils/validation';
import { EXTRA_SHIFT_DURATION_OPTIONS } from '@src/constants/cleaningSubscriptions';
import { OPERATOR_COMPENSATION_TYPES } from '@src/constants/service';

import type { IntlShape } from 'react-intl';

type CreateExtraServiceModalProps = {
  ...IntlShape,
  handleSubmit: () => void,
};

export function CreateExtraServiceModal(props: CreateExtraServiceModalProps) {
  const { handleSubmit, intl: { formatMessage } } = props;

  return (
    <Form className="row justify-content-center" onSubmit={handleSubmit}>
      <div className="col-sm-10">
        <h3>
          <FormattedMessage
            id="CreateExtraServiceModal.title"
            defaultMessage="Crear Servicio Extra"
            description="CreateExtraServiceModal title"
          />
        </h3>
      </div>
      <div className="col-sm-10">
        <FormGroupSelectField
          name="estimated_hours"
          size="sm"
          label={formatMessage(messages.estimatedHoursLabel)}
          options={EXTRA_SHIFT_DURATION_OPTIONS}
          defaultText={formatMessage(messages.estimatedHoursPlaceholder)}
          validate={[isRequired]}
        />

        <FormGroupDatetimeField
          name="datetime"
          label={formatMessage(messages.datetimeLabel)}
          size="sm"
          placeholder="2018-01-01"
          validate={[isRequired]}
        />

        <FormGroupSelectField
          name="operator_compensation_type"
          label="Paga el servicio al operador elegido:"
          options={OPERATOR_COMPENSATION_TYPES}
          defaultText={OPERATOR_COMPENSATION_TYPES[0].text}
          validate={[isRequired]}
        />

        <FormGroupInputField
          name="description"
          label={formatMessage(messages.descriptionLabel)}
          placeholder={formatMessage(messages.descriptionPlaceholder)}
        />

        <Button
          type="submit"
          size="sm"
          btnStyle="primary"
          onClick={handleSubmit}
        >
          <FormattedMessage
            id="CreateExtraServiceModal.saveButton"
            defaultMessage="Guardar"
            description="CreateExtraServiceModal addButton"
          />
        </Button>
      </div>
    </Form>
  );
}

export default compose(
  injectIntl,
  reduxForm({
    form: 'createExtraServiceForm',
  })
)(CreateExtraServiceModal);
