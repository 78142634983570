// @flow
import React, { Component } from 'react';
import cx from 'classnames';

import Button from '@src/components/Button';

import type { Node as ReactNode } from 'react';
import styles from './Dropdown.module.scss';

type DropdownProps = {
  className?: string,
  title: string,
  btnClassName?: string,
  children?: ReactNode,
};

export default class Dropdown extends Component<DropdownProps, *> {
  toggleMenu: Function;
  handleBlur: Function;

  constructor() {
    super();

    this.toggleMenu = this.toggleMenu.bind(this);
    this.handleBlur = this.handleBlur.bind(this);

    this.state = {
      showMenu: false,
    };
  }

  toggleMenu() {
    this.setState(state => ({ showMenu: !state.showMenu }));
  }

  handleBlur(e: any) {
    e.preventDefault();
    const { currentTarget, relatedTarget } = e;

    const isDropDownItem = target => {
      const classRegex = /dropdown-item/;
      return target && target.className && classRegex.test(target.className);
    };

    if (
      !currentTarget.contains(relatedTarget) ||
      isDropDownItem(relatedTarget)
    ) {
      // setTimeout allows elements to be clickable before disappearing
      setTimeout(() => this.setState(() => ({ showMenu: false })), 200);
    }
  }

  render() {
    const { className, title, btnClassName, children } = this.props;

    const showClassName = this.state.showMenu ? 'show' : null;

    const modifiedChildren = React.Children.map(children, child =>
      React.cloneElement(child, {
        className: cx('dropdown-item', child.props.className),
      })
    );

    return (
      <div
        className={cx('dropdown', className, showClassName)}
        onBlur={this.handleBlur}
      >
        <Button
          btnStyle="link"
          className={cx(btnClassName, 'dropdown-toggle')}
          onClick={this.toggleMenu}
        >
          {title}
        </Button>

        <div
          className={cx('dropdown-menu', styles.dropdownLeft, showClassName)}
        >
          {modifiedChildren}
        </div>
      </div>
    );
  }
}
