// @flow

import React from 'react';
import { FormattedMessage } from 'react-intl';
import cx from 'classnames';

import PillBadge from '@src/components/PillBadge';

import {
  STATUS_CREATED,
  STATUS_PENDING_APPROVAL,
  STATUS_PENDING_ACCEPTANCE,
  STATUS_ACCEPTED,
  STATUS_CLOSED,
  STATUS_CANCELED,
  STATUS_PARTIALLY_DELIVERED,
} from '@src/constants/orders';

import type { OrderStatusEnum } from '@src/types/models';

type OrderStatusBadgeProps = {
  status: OrderStatusEnum,
};

export default function OrderStatusBadge(props: OrderStatusBadgeProps) {
  const status = props.status.toUpperCase();

  return (
    <PillBadge
      badgeStyle={cx(
        status === STATUS_ACCEPTED && 'warning',
        status === STATUS_CANCELED && 'danger',
        status === STATUS_CLOSED && 'success',
        status === STATUS_PENDING_ACCEPTANCE && 'dark',
        status === STATUS_PENDING_APPROVAL && 'dark',
        status === STATUS_CREATED && 'dark',
        status === STATUS_PARTIALLY_DELIVERED && 'warning'
      )}
    >
      {status === STATUS_CREATED && (
        <FormattedMessage
          id="OrderStatusBadge.status.CREATED"
          defaultMessage="creada"
          description="Status name"
        />
      )}
      {status === STATUS_PENDING_APPROVAL && (
        <FormattedMessage
          id="OrderStatusBadge.status.PENDING_APPROVAL"
          defaultMessage="pendiente de aprobación"
          description="Status name"
        />
      )}
      {status === STATUS_PENDING_ACCEPTANCE && (
        <FormattedMessage
          id="OrderStatusBadge.status.PENDING_ACCEPTANCE"
          defaultMessage="pendiente de aceptar"
          description="Status name"
        />
      )}
      {status === STATUS_ACCEPTED && (
        <FormattedMessage
          id="OrderStatusBadge.status.ACCEPTED"
          defaultMessage="aceptada"
          description="Status name"
        />
      )}
      {status === STATUS_CLOSED && (
        <FormattedMessage
          id="OrderStatusBadge.status.CLOSED"
          defaultMessage="entregado"
          description="Status name"
        />
      )}
      {status === STATUS_CANCELED && (
        <FormattedMessage
          id="OrderStatusBadge.status.CANCELED"
          defaultMessage="cancelada"
          description="Status name"
        />
      )}
      {status === STATUS_PARTIALLY_DELIVERED && (
        <FormattedMessage
          id="OrderStatusBadge.status.PARTIALLY_DELIVERED"
          defaultMessage="Parcialmente entregada"
          description="Status name"
        />
      )}
    </PillBadge>
  );
}
