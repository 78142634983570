// @flow
import React from 'react';
import cx from 'classnames';

import styles from './Checkbox.module.scss';

type CheckboxProps = {
  className?: string,
  value?: any,
  checked?: boolean,
  label?: string,
  handleChange: Function,
};

export default function Checkbox(props: CheckboxProps) {
  const { className, value, checked, label, handleChange } = props;

  return (
    <label className={cx(styles.label, className)}>
      <input
        type="checkbox"
        value={value}
        checked={checked}
        onChange={handleChange}
      />
      <span>{label}</span>
    </label>
  );
}
