// @flow
import React from 'react';
import gql from 'graphql-tag';
import { injectIntl, FormattedMessage } from 'react-intl';
import { reduxForm } from 'redux-form';
import compose from 'lodash/flowRight';
import moment from 'moment-timezone';

import messages from './messages';
import Button from '@src/components/Button';
import Form from '@src/components/Form';
import FormGroupSelectField from '@src/components/FormGroupSelectField';
import FormGroupInputField from '@src/components/FormGroupInputField';
import FormGroupDatetimeField from '@src/components/FormGroupDatetimeField';
import { isRequired } from '@src/utils/validation';

import type { IntlShape } from 'react-intl';
import type { SelectOption } from '@src/components/Select';

export type PaymentAccount = {
  id: string,
  name: string,
};

type CreateInvoicePaymentModalProps = {
  ...IntlShape,
  payment_accounts: Array<SelectOption>,
  handleSubmit: () => void,
  closeModal: () => void,
};

export function CreateInvoicePaymentModal(
  props: CreateInvoicePaymentModalProps
) {
  const {
    payment_accounts,
    handleSubmit,
    closeModal,
    intl: { formatMessage },
  } = props;

  return (
    <Form onSubmit={handleSubmit}>
      <div className="modal-header">
        <h3 className="modal-title">
          <FormattedMessage
            id="CreateInvoicePaymentModal.title"
            defaultMessage="Registrar Pago"
            description="CancelServiceModal title"
          />
        </h3>
      </div>
      <div className="modal-body">
        <FormGroupSelectField
          name="payment_account_id"
          label={formatMessage(messages.paymentAccounts)}
          options={payment_accounts}
          validate={[isRequired]}
        />
        <FormGroupInputField
          name="amount"
          label={formatMessage(messages.amountLabel)}
          type="number"
          placeholder={formatMessage(messages.amountPlaceholder)}
          validate={[isRequired]}
        />
        <FormGroupDatetimeField
          name="paid_at"
          label={formatMessage(messages.paidAtLabel)}
          validate={[isRequired]}
          maxDate={moment().endOf('day')}
        />
        <FormGroupInputField
          name="reference"
          label={formatMessage(messages.referenceLabel)}
          type="text"
          placeholder={formatMessage(messages.referencePlaceholder)}
        />
        <FormGroupInputField
          name="description"
          label={formatMessage(messages.descriptionLabel)}
          type="text"
          placeholder={formatMessage(messages.descriptionPlaceholder)}
        />
      </div>
      <div className="modal-footer">
        <Button btnStyle="light" onClick={() => closeModal()}>
          <FormattedMessage
            id="CreateInvoicePaymentModal.btn.cancel"
            defaultMessage="Cancelar"
            description="CreateInvoicePaymentModal cancel button"
          />
        </Button>
        <Button btnStyle="success" type="submit" onClick={handleSubmit}>
          <FormattedMessage
            id="CreateInvoicePaymentModal.btn.confirm"
            defaultMessage="Registrar Pago"
            description="CreateInvoicePaymentModal confirm button"
          />
        </Button>
      </div>
    </Form>
  );
}

CreateInvoicePaymentModal.fragments = {
  payment_accounts: gql`
    fragment CreateInvoicePaymentAccounts on PaymentAccount {
      id
      name
    }
  `,
};

export default compose(
  injectIntl,
  reduxForm({
    form: 'createInvoicePaymentForm',
  })
)(CreateInvoicePaymentModal);
