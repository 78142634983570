// @flow
import type { Weekday } from '@src/types/utils';

export default function weekdayToNumber(weekday: Weekday) {
  const day = weekday.toLowerCase();

  switch (day) {
    case 'sunday':
      return 0;
    case 'monday':
      return 1;
    case 'tuesday':
      return 2;
    case 'wednesday':
      return 3;
    case 'thursday':
      return 4;
    case 'friday':
      return 5;
    case 'saturday':
      return 6;

    default:
      return 0;
  }
}
