// @flow
import { ApolloClient } from 'apollo-client';
import { ApolloLink } from 'apollo-link';
import { HttpLink } from 'apollo-link-http';
import { setContext } from 'apollo-link-context';
import {
  InMemoryCache,
  IntrospectionFragmentMatcher,
} from 'apollo-cache-inmemory';
import { onError } from 'apollo-link-error';
import httpStatus from 'http-status';
import { toastr } from 'react-redux-toastr';

import { getApiUrl } from '@src/lib/api/utils';
import { get as getAuthToken, remove as logOut } from '@src/lib/auth';
import { log as logError } from '@src/lib/errorReporter';
import { replace as redirect } from '@src/utils/location';

const GRAPHQL_ENDPOINT = process.env.REACT_APP_GRAPHQL_ENDPOINT || 'graphql';
const API_URL = `${getApiUrl()}/${GRAPHQL_ENDPOINT}`;

// $FlowIgnore
const httpLink = new HttpLink({ uri: API_URL });

const authLink = setContext((_, { headers }) => {
  const token = getAuthToken();

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const unauthorizedLink = onError(({ networkError }) => {
  if (
    networkError &&
    networkError.response &&
    networkError.response.status === httpStatus.UNAUTHORIZED
  ) {
    logOut();
    redirect('/login');
  }

  if (
    networkError &&
    networkError.response &&
    networkError.response.status === httpStatus.INTERNAL_SERVER_ERROR
  ) {
    logError(networkError);
    toastr.error('Error 500!', networkError.message);
  }
});

const fragmentMatcher = new IntrospectionFragmentMatcher({
  /**
    TODO: Right now this is hardcoded, and if the graphql schema changes,
    it would need to be updated to the new values. But we can do this automatically.
    https://www.apollographql.com/docs/react/advanced/fragments.html#fragment-matcher
  **/
  introspectionQueryResultData: {
    __schema: {
      types: [
        {
          kind: 'UNION',
          name: 'SubscriptionStatementEntryConceptable',
          possibleTypes: [
            {
              name: 'CleaningSubscription',
            },
            {
              name: 'ExtraService',
            },
            {
              name: 'Service',
            },
          ],
        },
      ],
    },
  },
});

const client = new ApolloClient({
  link: ApolloLink.from([unauthorizedLink, authLink, httpLink]),
  cache: new InMemoryCache({ fragmentMatcher }),
});

export default client;
