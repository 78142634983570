// @flow
import * as React from 'react';
import { Field } from 'redux-form';

import Select from '@src/components/Select';
import FormGroup from '@src/components/FormGroup';
import isInputInvalid from '@src/utils/isInputInvalid';

import type { SelectProps } from '@src/components/Select';

type FormGroupSelectProps = SelectProps & {
  name: string,
  label?: *,
};

type RFFormGroupSelectProps = FormGroupSelectProps & {
  input: *,
  meta: {
    active: boolean,
    invalid: boolean,
    touched: boolean,
    form: string,
    error?: string,
  },
};

export function RFFormGroupSelect(
  props: RFFormGroupSelectProps
): React.Element<*> {
  const { className, label, size, meta, input, ...rest } = props;
  const error = (isInputInvalid(meta) && meta.error) || '';
  const htmlFor = `${meta.form}-${input.name || Math.random()}`;

  return (
    <FormGroup
      className={className}
      label={label}
      error={error}
      htmlFor={htmlFor}
    >
      <Select
        isInvalid={!!error}
        size={size}
        id={htmlFor}
        onChange={input.onChange}
        onBlur={input.onBlur}
        {...input}
        {...rest}
      />
    </FormGroup>
  );
}

export default function FormGroupSelect(props: FormGroupSelectProps) {
  return <Field {...props} component={RFFormGroupSelect} />;
}
