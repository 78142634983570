// @flow
import React from 'react';
import gql from 'graphql-tag';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import cx from 'classnames';

import styles from './MonthlyCleaningInvoicingModal.module.scss';
import Button from '@src/components/Button';
import Table from '@src/components/Table';
import OfficeInfo from '@src/components/OfficeInfo';
import Currency from '@src/components/i18n/Currency';
import OrderStatusBadge from '@src/components/OrderStatusBadge';
import TimestampSummary from '@src/components/TimestampSummary';

import type {
  OrderStatusEnum,
  CompanySettingsInvoicingEnum,
} from '@src/types/models';

export type Order = {
  id: string,
  status: OrderStatusEnum,
  total_price: number,
  currency: string,
  created_at: string,
  closed_at?: string,
  office: {
    id: string,
    name?: string,
    company: {
      id: string,
      name: string,
      invoicing: CompanySettingsInvoicingEnum,
    },
  },
  created_by: {
    id: string,
    first_name: string,
    last_name: string,
  },
};

type MonthlyCleaningInvoicingModalProps = {
  orders: Array<Order>,
  onSubmit: () => void,
  closeModal: () => void,
};

function MonthlyCleaningInvoicingModal(
  props: MonthlyCleaningInvoicingModalProps
) {
  const { orders, onSubmit, closeModal } = props;

  const _onSubmit = () => {
    onSubmit();
    closeModal();
  };

  return (
    <div>
      <div className="modal-header">
        <h3 className="modal-title">
          <FormattedMessage
            id="MonthlyCleaningInvoicingModal.title"
            defaultMessage="Generar factura mensual de limpieza"
            description="MonthlyCleaningInvoicingModal title"
          />
        </h3>
      </div>
      <div className="modal-body">
        <p>
          <FormattedMessage
            id="MonthlyCleaningInvoicingModal.title"
            defaultMessage="Las siguientes órdenes de servicio entregadas durante el periodo de facturación seleccionado serán agregadas a la factura: "
            description="MonthlyCleaningInvoicingModal title"
          />
        </p>
        <Table className={cx('table-striped', styles.table)}>
          <thead>
            <tr>
              <th>
                <FormattedMessage
                  id="MonthlyCleaningInvoicingModal.order.id"
                  defaultMessage="ID"
                  description="MonthlyCleaningInvoicingModal order id"
                />
              </th>
              <th>
                <FormattedMessage
                  id="MonthlyCleaningInvoicingModal.order.office"
                  defaultMessage="Ubicación"
                  description="MonthlyCleaningInvoicingModal order office"
                />
              </th>
              <th>
                <FormattedMessage
                  id="MonthlyCleaningInvoicingModal.order.total_price"
                  defaultMessage="Total"
                  description="MonthlyCleaningInvoicingModal order total_price"
                />
              </th>
              <th>
                <FormattedMessage
                  id="MonthlyCleaningInvoicingModal.order.status"
                  defaultMessage="Status"
                  description="MonthlyCleaningInvoicingModal order status"
                />
              </th>
              <th>
                <FormattedMessage
                  id="MonthlyCleaningInvoicingModal.order.closed_at"
                  defaultMessage="Entrega"
                  description="MonthlyCleaningInvoicingModal order closed_at"
                />
              </th>
              <th>
                <FormattedMessage
                  id="MonthlyCleaningInvoicingModal.order.created_at"
                  defaultMessage="Creación"
                  description="MonthlyCleaningInvoicingModal order created_at"
                />
              </th>
            </tr>
          </thead>
          <tbody>
            {orders.length === 0 && (
              <tr>
                <td colSpan="100">
                  <FormattedMessage
                    id="MonthlyCleaningInvoicingModal.orders.empty"
                    defaultMessage="No hay órdenes de servicio para el periodo"
                    description="MonthlyCleaningInvoicingModal orders empty"
                  />
                </td>
              </tr>
            )}
            {orders.map(order => (
              <tr key={order.id}>
                <td>
                  <Link to={`/order/${order.id}`}>{order.id}</Link>
                </td>
                <td>
                  <OfficeInfo office={order.office} />
                </td>
                <td>
                  <Currency
                    value={order.total_price}
                    currency={order.currency}
                  />
                </td>
                <td>
                  <OrderStatusBadge status={order.status} />
                </td>
                <td>
                  {order.closed_at && (
                    <TimestampSummary value={order.closed_at} />
                  )}
                </td>
                <td>
                  <TimestampSummary value={order.created_at} />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
      <div className="modal-footer">
        <Button btnStyle="light" onClick={() => closeModal()}>
          <FormattedMessage
            id="MonthlyCleaningInvoicingModal.btn.cancel"
            defaultMessage="Cancelar"
            description="MonthlyCleaningInvoicingModal cancel button"
          />
        </Button>
        <Button btnStyle="success" onClick={() => _onSubmit()}>
          <FormattedMessage
            id="MonthlyCleaningInvoicingModal.btn.confirm"
            defaultMessage="Generar Factura"
            description="MonthlyCleaningInvoicingModal confirm button"
          />
        </Button>
      </div>
    </div>
  );
}

MonthlyCleaningInvoicingModal.fragments = {
  orders: gql`
    fragment MonthlyCleaningInvoicingModalOrders on Order {
      id
      status
      total_price
      currency
      created_at
      closed_at
      office {
        id
        name
        company {
          id
          name
          invoicing
        }
      }
      created_by {
        id
        first_name
        last_name
      }
    }
  `,
};

export default MonthlyCleaningInvoicingModal;
