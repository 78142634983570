// @flow
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import compose from 'lodash/flowRight';

import { Link } from 'react-router-dom';
import Button from '@src/components/Button';

import type { IntlShape } from 'react-intl';
import type { Invoice } from '@src/components/InvoiceableInvoices';

type InvoicesCreatedModalProps = {
  ...IntlShape,
  invoices: Array<Invoice>,
  closeModal: () => void,
};

export function InvoicesCreatedModal(props: InvoicesCreatedModalProps) {
  const { invoices, closeModal } = props;

  return (
    <div className="row justify-content-center">
      <div className="col-sm-10">
        <h3>
          <FormattedMessage
            id="InvoicesCreatedModal.title"
            defaultMessage="Facturas creadas y en proceso de emisión..."
            description="InvoicesCreatedModal title"
          />
        </h3>
      </div>

      <div className="col-sm-10">
        <p>Puedes ver el status de emisión de cada factura individual:</p>
        <ul>
          {invoices.map(invoice => (
            <li key={invoice.id}>
              <Link to={`/invoice/${invoice.id}`} target="_blank">
                {`Factura: ${invoice.description}`}
              </Link>
            </li>
          ))}
        </ul>

        <div className="modal-footer">
          <Button btnStyle="light" size="sm" onClick={closeModal}>
            <FormattedMessage
              id="CreateInvoicesModal.closeButton"
              defaultMessage="Cerrar"
              description="Create Invoices Modal closeButton"
            />
          </Button>
        </div>
      </div>
    </div>
  );
}

export default compose(injectIntl)(InvoicesCreatedModal);
