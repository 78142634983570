// @flow
import React from 'react';
import compose from 'lodash/flowRight';

import redux from './redux';
import graphql from './graphql';
import CreateInvoicePaymentModal from './CreateInvoicePaymentModal';

import type { PaymentAccount } from './CreateInvoicePaymentModal';

type CreateInvoicePaymentModalContainerProps = {
  data: {
    loading: boolean,
    payment_accounts?: {
      edges: Array<{
        node: PaymentAccount,
      }>,
    },
  },
  closeModal: () => void,
  onSubmit: () => void,
};

export class CreateInvoicePaymentModalContainer extends React.Component<
  CreateInvoicePaymentModalContainerProps
> {
  getPaymentAccounts() {
    const { data: { payment_accounts } } = this.props;

    if (!payment_accounts) {
      return [];
    }

    return payment_accounts.edges.map(edge => ({
      text: edge.node.name,
      value: edge.node.id,
    }));
  }

  render() {
    const { closeModal, onSubmit } = this.props;
    const payment_accounts = this.getPaymentAccounts();
    return (
      <CreateInvoicePaymentModal
        payment_accounts={payment_accounts}
        closeModal={closeModal}
        onSubmit={onSubmit}
      />
    );
  }
}

export default compose(redux, graphql)(CreateInvoicePaymentModalContainer);
