// @flow
import * as React from 'react';
import cx from 'classnames';

export type InputProps = {
  className?: string,
  size?: 'sm' | 'lg',
  isInvalid?: boolean,
};

export default function Input(props: InputProps) {
  const { size, className, isInvalid, ...rest } = props;

  const finalClassName = cx(
    'form-control',
    className,
    size && `form-control-${size}`,
    isInvalid && 'is-invalid'
  );

  return <input className={finalClassName} autoComplete="off" {...rest} />;
}
