// @flow
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { reduxForm } from 'redux-form';
import compose from 'lodash/flowRight';
import moment from 'moment-timezone';

import messages from './messages';
import withInitialValues from './withInitialValues';
import Button from '@src/components/Button';
import Form from '@src/components/Form';
import FormGroupInputField from '@src/components/FormGroupInputField';
import FormGroupSelectField from '@src/components/FormGroupSelectField';
import FormGroupDatetimeField from '@src/components/FormGroupDatetimeField';
import { isRequired } from '@src/utils/validation';
import { MANUAL_SERVICE_CHECK_REASONS } from '@src/constants/service';

import type { IntlShape } from 'react-intl';
import type { Service } from '@src/pages/resources/service/view/ViewServiceDetails';

type CreateServiceCheckInModalProps = {
  ...IntlShape,
  service: Service,
  handleSubmit: () => void,
};

export function CreateServiceCheckInModal(
  props: CreateServiceCheckInModalProps
) {
  const { service, handleSubmit, intl: { formatMessage } } = props;

  const allowedDate = service ? moment(service.datetime) : null;

  return (
    <Form className="row justify-content-center" onSubmit={handleSubmit}>
      <div className="col-sm-10">
        <h3>
          <FormattedMessage
            id="CreateServiceCheckInModal.title"
            defaultMessage="Crear check-in para el servicio #{id}"
            description="CreateServiceCheckInModal title"
            values={{ id: service.id }}
          />
        </h3>
      </div>
      <div className="col-sm-10">
        <FormGroupInputField
          name="operator"
          label={formatMessage(messages.operatorLabel)}
          disabled
        />
        <FormGroupSelectField
          name="manual_check_reason"
          label={formatMessage(messages.manualCheckReasonLabel)}
          options={MANUAL_SERVICE_CHECK_REASONS}
          defaultText={formatMessage(messages.manualCheckReasonPlaceholder)}
          validate={[isRequired]}
        />
        <FormGroupDatetimeField
          name="check_time"
          label={formatMessage(messages.checkTimeLabel)}
          validate={[isRequired]}
          minDate={allowedDate ? allowedDate.startOf('day') : undefined}
          maxDate={allowedDate ? allowedDate.endOf('day') : undefined}
        />
        <Button type="submit" btnStyle="primary" onClick={handleSubmit}>
          <FormattedMessage
            id="CreateServiceCheckInModal.createButton"
            defaultMessage="Crear check"
            description="CreateServiceCheckInModal createButton"
          />
        </Button>
      </div>
    </Form>
  );
}

export default compose(
  injectIntl,
  withInitialValues,
  reduxForm({
    form: 'createServiceCheckInForm',
  })
)(CreateServiceCheckInModal);
