// @flow
import React from 'react';
import cx from 'classnames';

import styles from './Label.module.scss';

import type { Node } from 'react';

type LabelProps = {
  className?: string,
  children: Node,
  htmlFor?: string,
};

export default function Label(props: LabelProps) {
  const { htmlFor, className, children } = props;
  return (
    <label htmlFor={htmlFor} className={cx(className, styles.container)}>
      {children}
    </label>
  );
}
