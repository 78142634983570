// @flow
import React from 'react';

import UpdateServiceNoShowModal from './UpdateServiceNoShowModal';

import type { ServiceNoShow } from '@src/pages/resources/service/view/ViewServiceNoShows';

type UpdateServiceNoShowModalContainerProps = {
  serviceNoShow: ServiceNoShow,
  handleSubmit: () => void,
  closeModal: () => void,
};

export default function UpdateServiceNoShowModalContainer(
  props: UpdateServiceNoShowModalContainerProps
) {
  return (
    <UpdateServiceNoShowModal
      serviceNoShow={props.serviceNoShow}
      onSubmit={props.handleSubmit}
      closeModal={props.closeModal}
    />
  );
}
