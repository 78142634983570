// @flow

export const SERVICE_CHECK_OPTIONS = [
  {
    text: 'Check In',
    value: 'CHECK_IN',
  },
  {
    text: 'En Camino',
    value: 'ON_THE_WAY',
  },
];

export const MANUAL_SERVICE_CHECK_REASONS = [
  {
    text: 'Fallo de la app',
    value: 'APP_FAILURE',
  },
  {
    text: 'No tiene internet',
    value: 'NO_INTERNET',
  },
  {
    text: 'No tiene teléfono',
    value: 'NO_PHONE',
  },
  {
    text: 'Asignación después de servicio',
    value: 'ASSIGNMENT_AFTER_SERVICE',
  },
  {
    text: 'Otro',
    value: 'OTHER',
  },
];

export const OPERATOR_COMPENSATION_TYPES = [
  {
    text: 'normal',
    value: 'NORMAL',
  },
  {
    text: 'tiempo extra',
    value: 'EXTRA',
  },
  {
    text: 'tiempo extra nocturno',
    value: 'EXTRA_NIGHT',
  },
  {
    text: 'horas extra compensables',
    value: 'EXTRA_REWARDABLE',
  },
];

export const NO_SHOW_TYPES = [
  {
    text: 'injustificadamente',
    value: 'UNJUSTIFIED',
  },
  {
    text: 'vacaciones',
    value: 'HOLIDAYS',
  },
  {
    text: 'reasignación de servicio',
    value: 'REASSIGNMENT',
  },
  {
    text: 'incapacidad',
    value: 'INCAPACITY',
  },
  {
    text: 'tiempo por tiempo',
    value: 'TIME_FOR_TIME',
  },
  {
    text: 'cumpleaños',
    value: 'BIRTHDAY',
  },
  {
    text: 'empleado del mes',
    value: 'EMPLOYEE_OF_THE_MONTH',
  },
  {
    text: 'resguardo',
    value: 'SHELTERING',
  },
  {
    text: 'Permiso por fallecimiento',
    value: 'DECEASE',
  },
  {
    text: 'Permiso de ausencia',
    value: 'PERMIT_FOR_ABSCENCE',
  },
];

export const OPERATOR_STATUS_UNKNOWN = 'UNKNOWN';
export const OPERATOR_STATUS_ON_THE_WAY = 'ON_THE_WAY';
export const OPERATOR_STATUS_CHECK_IN = 'CHECK_IN';
export const OPERATOR_STATUS_CHECK_OUT = 'CHECK_OUT';

export const OPERATOR_STATUS = [
  OPERATOR_STATUS_UNKNOWN,
  OPERATOR_STATUS_ON_THE_WAY,
  OPERATOR_STATUS_CHECK_IN,
];

export const STATUS_CREATED = 'CREATED';
export const STATUS_ASSIGNED = 'ALIADA_ASSIGNED';
export const STATUS_UNASSIGNED = 'UNASSIGNED';
export const STATUS_FINISHED = 'FINISHED';
export const STATUS_CANCELED = 'CANCELED';

export const STATUS = [
  STATUS_CREATED,
  STATUS_ASSIGNED,
  STATUS_UNASSIGNED,
  STATUS_FINISHED,
  STATUS_CANCELED,
];
