// @flow
import React from 'react';
import cx from 'classnames';
import { Helmet } from 'react-helmet';

import styles from './Page.module.scss';

import type { Node } from 'react';

type PageProps = {
  className?: string,
  title?: string,
  children: Node,
};

export default function Page(props: PageProps) {
  const { className, children, title } = props;

  return (
    <div className={cx('container-fluid', styles.container, className)}>
      <Helmet>
        <title>{title || null}</title>
      </Helmet>
      {children}
    </div>
  );
}
