// @flow
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import compose from 'lodash/flowRight';

import NavBarFragments from '@src/components/NavBar/NavBarFragments';
import { loggedIn } from '@src/lib/auth';

const withData = graphql(
  gql`
    query AppData {
      current_admin {
        ...AdminProfile
      }
    }
    ${NavBarFragments.adminProfile}
  `,
  {
    skip: !loggedIn(),
  }
);

export default compose(withData);
