// @flow
import React from 'react';

import { OLD_ADMIN_URL } from '@src/constants/config';

import type { Node } from 'react';

type OldAdminLinkProps = {
  className?: string,
  to: string,
  children?: Node,
};

export default function OldAdminLink(props: OldAdminLinkProps) {
  const { className, to, children, ...rest } = props;
  return (
    <a
      className={props.className}
      href={`${OLD_ADMIN_URL}${props.to}`}
      {...rest}
    >
      {props.children}
    </a>
  );
}
