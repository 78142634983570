// @flow
/* eslint-disable react/style-prop-object */
import React from 'react';
import { FormattedNumber } from 'react-intl';

export default function Currency({
  style,
  currencyDisplay,
  value,
  ...rest
}: *) {
  const valueInDecimal = value / 100;
  return (
    <FormattedNumber
      style="currency"
      currencyDisplay="symbol"
      value={valueInDecimal}
      {...rest}
    />
  );
}
