// @flow
import { defineMessages } from 'react-intl';

const messages = defineMessages({
  reasonLabel: {
    id: 'DeactivateOperatorModal.reasonLabel',
    defaultMessage: 'Razón de baja',
    description: 'Label for reason field',
  },
  reasonPlaceholder: {
    id: 'DeactivateOperatorModal.reasonPlaceholder',
    defaultMessage: 'Selecciona una razón',
    description: 'Placeholder for reason field',
  },
  rehirableLabel: {
    id: 'DeactivateOperatorModal.rehirableLabel',
    defaultMessage: 'Disponible para recontratar',
    description: 'Label for rehirable field',
  },
  rehirablePlaceholder: {
    id: 'DeactivateOperatorModal.rehirablePlaceholder',
    defaultMessage: 'Selecciona un valor',
    description: 'Placeholder for rehirable field',
  },
  commentLabel: {
    id: 'DeactivateOperatorModal.commentLabel',
    defaultMessage: 'Comentarios',
    description: 'Label for comment field',
  },
  commentPlaceholder: {
    id: 'DeactivateOperatorModal.commentPlaceholder',
    defaultMessage: 'Comentarios...',
    description: 'Placeholder for comment field',
  },
});

export default messages;
