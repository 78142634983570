// @flow
import { defineMessages } from 'react-intl';

const messages = defineMessages({
  estimatedHoursLabel: {
    id: 'CreateExtraServiceForm.estimatedHoursLabel',
    defaultMessage: 'Duración del servicio',
    description: 'Label for default estimated_hours field',
  },
  estimatedHoursPlaceholder: {
    id: 'CreateExtraServiceForm.estimatedHoursPlaceholder',
    defaultMessage: 'Selecciona una duración...',
    description: 'Placeholder for default estimated_hours field',
  },
  datetimeLabel: {
    id: 'CreateExtraServiceForm.datetimeLabel',
    defaultMessage: 'Horario',
    description: 'Label for default datetime field',
  },
  descriptionLabel: {
    id: 'CreateExtraServiceForm.descriptionLabel',
    defaultMessage: 'Descripción del servicio',
    description: 'Label for description field',
  },
  descriptionPlaceholder: {
    id: 'CreateExtraServiceForm.descriptionPlaceholder',
    defaultMessage: '¿Para qué es este servicio?',
    description: 'Placeholder for description field',
  },
});

export default messages;
