// @flow
import React from 'react';
import cx from 'classnames';

import type { Node as ReactNode } from 'react';

type TableProps = {
  className?: string,
  containerClassName?: string,
  children?: ReactNode,
};

export default function Table(props: TableProps) {
  const { className, children, containerClassName = '' } = props;
  return (
    <div className={cx('table-responsive', containerClassName)}>
      <table className={cx('table', className)}>{children}</table>
    </div>
  );
}
