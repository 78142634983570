// @flow
import withProps from 'recompose/withProps';

export default withProps((props: *) => {
  if (!props.serviceNoShow) {
    return {};
  }

  const { serviceNoShow } = props;

  return {
    initialValues: {
      ...serviceNoShow,
      no_show_type: serviceNoShow.no_show_type.toUpperCase(),
    },
  };
});
