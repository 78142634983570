// @flow

export const OPERATOR_ROLE = 'OPERATOR';
export const TEAM_LEADER_ROLE = 'TEAM_LEADER';
export const BUILDING_MANAGER_ROLE = 'BUILDING_MANAGER';
export const SUPERVISOR_ROLE = 'SUPERVISOR';
export const ONBOARDING_STATUS = 'ONBOARDING';
export const ONBOARDED_STATUS = 'ONBOARDED';
export const ACTIVE_STATUS = 'ACTIVE';
export const INACTIVE_STATUS = 'INACTIVE';

export const OPERATOR_STATUS_OPTIONS = [
  {
    text: 'Activo',
    value: ACTIVE_STATUS,
  },
  {
    text: 'Inactivo',
    value: INACTIVE_STATUS,
  },
  {
    text: 'En capacitación',
    value: ONBOARDING_STATUS,
  },
  {
    text: 'Finalizó capacitación',
    value: ONBOARDED_STATUS,
  },
];

export const OPERATOR_ROLE_OPTIONS = [
  {
    text: 'Operador',
    value: OPERATOR_ROLE,
  },
  {
    text: 'Team Leader',
    value: TEAM_LEADER_ROLE,
  },
  {
    text: 'Building Manager',
    value: BUILDING_MANAGER_ROLE,
  },
  {
    text: 'Supervisor',
    value: SUPERVISOR_ROLE,
  },
];

export const DOWNTIME_REASON_OPTIONS = [
  {
    text: 'Ausencias',
    value: 'ABSENCES',
  },
  {
    text: 'Actitud',
    value: 'ATTITUDE',
  },
  {
    text: 'Calidad',
    value: 'QUALITY',
  },
  {
    text: 'Sospecha de robo',
    value: 'THEFT_SUSPICION',
  },
  {
    text: 'Mejor oportunidad laboral',
    value: 'BETTER_JOB_OFFER',
  },
  {
    text: 'Problemas personales',
    value: 'PERSONAL_PROBLEMS',
  },
  {
    text: 'Problemas de salud',
    value: 'HEALTH',
  },
  {
    text: 'Temporal',
    value: 'TEMPORARY',
  },
  {
    text: 'Desconocida',
    value: 'UNKNOWN',
  },
];
