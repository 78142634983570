// @flow
import { defineMessages } from 'react-intl';

const messages = defineMessages({
  operatorPlaceholder: {
    id: 'taskOperatorForm.operatorPlaceholder',
    defaultMessage: 'Selecciona un operador...',
    description: 'Label for default Operator field',
  },
});

export default messages;
