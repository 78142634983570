// @flow
import * as React from 'react';
import { Field } from 'redux-form';
import cx from 'classnames';

import styles from './CheckboxField.module.scss';
import Checkbox from '@src/components/Checkbox';
import isInputInvalid from '@src/utils/isInputInvalid';

type CheckboxFieldProps = {
  name: string,
  className?: string,
};

type RFCheckboxProps = {
  ...CheckboxFieldProps,
  label: string,
  input: {
    value: boolean,
    onChange: (value: boolean) => void,
    onBlur: (value: boolean) => void,
  },
  label: string,
  meta: {
    active: boolean,
    invalid: boolean,
    touched: boolean,
    error?: string,
  },
};

export function RFCheckbox(props: RFCheckboxProps): React.Element<*> {
  const {
    className,
    input: { value, onChange, onBlur },
    name,
    meta,
    label,
  } = props;

  const handleChange = event => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;

    onBlur(value);
    return onChange(value);
  };

  const isInvalid = isInputInvalid(meta);

  return (
    <div className={cx(styles.container)}>
      <div className={cx(styles.checkbox)}>
        <Checkbox
          key={name}
          className={cx(styles.label, className)}
          value={value}
          checked={value}
          handleChange={handleChange}
          label={label}
        />
      </div>
      <div
        className={cx('form-control', 'd-none', { 'is-invalid': isInvalid })}
      />
      {isInvalid && (
        <div className={cx('form-text', 'invalid-feedback')}>{meta.error}</div>
      )}
    </div>
  );
}

export default function CheckboxField(props: CheckboxFieldProps) {
  return <Field {...props} type="checkbox" component={RFCheckbox} />;
}
