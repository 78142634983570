// @flow
import React, { Component } from 'react';
import compose from 'lodash/flowRight';

import redux from './redux';
import AssignServiceAvailableOperatorsModal from './AssignServiceAvailableOperatorsModal';
import graphql from './graphql';

import type { Operator, Service } from './';

type OperatorWithHoursPerWeek = Operator & { hours_per_week: number };

type AssignServiceAvailableOperatorsModalContainerProps = {
  data: {
    loading: boolean,
    operators_by_availability?: {
      edges: [
        {
          node: Operator,
        },
      ],
    },
  },
  service?: Service,
  handleSubmit: () => void,
  closeModal: () => void,
};

export class AssignServiceAvailableOperatorsModalContainer extends Component<
  AssignServiceAvailableOperatorsModalContainerProps
> {
  getOperators(): Array<OperatorWithHoursPerWeek> {
    const { data: { loading, operators_by_availability } } = this.props;

    if (loading || !operators_by_availability) {
      return [];
    }

    return operators_by_availability.edges.map(edge => {
      const { node } = edge;

      const recurrences = node.recurrences.filter(
        recurrence => recurrence.status === 'active'
      );

      const hours_per_week = recurrences.reduce((sum, recurrence) => {
        const weekdays = recurrence.weekdays.length;
        return sum + weekdays * recurrence.estimated_hours;
      }, 0);

      return {
        ...node,
        hours_per_week,
      };
    });
  }

  render() {
    const { service, handleSubmit, closeModal, data: { loading } } = this.props;
    const operators = this.getOperators();

    return (
      <AssignServiceAvailableOperatorsModal
        loading={loading}
        service={service}
        operators={operators}
        onSubmit={handleSubmit}
        closeModal={closeModal}
      />
    );
  }
}

export default compose(redux, graphql)(
  AssignServiceAvailableOperatorsModalContainer
);
