// @flow
import rg4js from 'raygun4js';

export function register() {
  if (process.env.NODE_ENV === 'production') {
    rg4js('enableCrashReporting', true);
    rg4js('apiKey', process.env.REACT_APP_RAYGUN_API_KEY);
  }
}

export function log(error: Error, customData?: *) {
  if (window.rg4js && process.env.NODE_ENV === 'production') {
    window.rg4js('send', {
      error,
      customData,
    });
  } else if (process.env.NODE_ENV === 'development') {
    console.log('Report Error [DEV]:', error, customData);
  }
}

export function setUser(email: string) {
  if (window.rg4js) {
    window.rg4js('setUser', {
      user: email,
      email,
      isAnonymous: false,
    });
  }
}

export function resetUser() {
  if (window.rg4js) {
    window.rg4js('getRaygunInstance').resetAnonymousUser();
  }
}
