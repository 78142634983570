// @flow
import React, { Component } from 'react';
import cx from 'classnames';

import styles from './Image.module.scss';

type ImageProps = {
  className?: string,
  background?: boolean,
  src: string,
  alt: string,
};

class Image extends Component<ImageProps, *> {
  render() {
    const { className, background, src, alt, ...rest } = this.props;

    return background ? (
      <div
        style={{
          backgroundImage: `url(${src})`,
        }}
        className={cx(styles.backgroundImage, className)}
        {...rest}
      />
    ) : (
      <img src={src} className={cx(className)} alt={alt} {...rest} />
    );
  }
}

export default Image;
