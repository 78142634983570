// @flow
import { addLocaleData } from 'react-intl';
import moment from 'moment-timezone';

export class I18N {
  defaultLocale: string;
  locale: string;
  messages: Object;
  timeZone: string;
  usesPolyfill: boolean;

  static getLanguageFromLocale(locale: string): string {
    const regionCodeSeparator = /[_-]+/;
    const [language] = locale.split(regionCodeSeparator);
    return language;
  }

  constructor(config: Object = {}) {
    if (!window.Intl) {
      this.loadIntlPolyfill();
      this.usesPolyfill = true;
    }

    this.defaultLocale = config.defaultLocale || 'es';
  }

  loadIntlPolyfill() {
    import(/* webpackChunkName: 'intlPolyfill' */ 'intl');
  }

  getDefaultLocale(): string {
    return this.defaultLocale;
  }

  getLocale(): string {
    return this.locale || this.getDefaultLocale();
  }

  setLocale(locale: string) {
    this.locale = locale;
  }

  async loadLocaleData(): Promise<void> {
    const locale = this.getLocale();
    const language = I18N.getLanguageFromLocale(locale);

    if (this.usesPolyfill) {
      // $FlowIgnore
      await import(/* webpackChunkName: 'intlPolyfillLocaleData.[request]' */ `intl/locale-data/jsonp/${locale}.js`);
    }

    // $FlowIgnore
    const localeData = await import(/* webpackChunkName: 'reactIntlLocaleData.[request]' */ `react-intl/locale-data/${language}.js`);

    await this.loadMomentLocaleData();

    addLocaleData(localeData);
  }

  async loadMomentLocaleData() {
    const locale = this.getLocale();
    const language = I18N.getLanguageFromLocale(locale);

    try {
      // $FlowIgnore
      await import(/* webpackChunkName: 'momentLocaleData.[request]' */ `moment/locale/${locale}.js`);
      moment.locale(locale);
    } catch (e) {
      // $FlowIgnore
      await import(/* webpackChunkName: 'momentLocaleData.[request]' */ `moment/locale/${language}.js`);
      moment.locale(language);
    }
  }

  getMessages() {
    return this.messages;
  }

  async loadMessages(locale: string) {
    // $FlowIgnore
    this.messages = await import(/* webpackChunkName: 'translations.[request]' */ `@src/translations/${locale}.json`);
  }

  getTimeZone() {
    return this.timeZone;
  }

  setTimeZone(timeZone: string) {
    this.timeZone = timeZone;
    this.setDefaultMomentTimeZone(timeZone);
  }

  setDefaultMomentTimeZone(timeZone?: string) {
    moment.tz.setDefault(timeZone);
  }
}

const defaultLocale = process.env.DEFAULT_LANGUAGE || 'es-MX';

export default new I18N({
  defaultLocale,
});
