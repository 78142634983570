// @flow
import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import cx from 'classnames';

import styles from './NavBar.module.scss';
import Logo from '@src/components/Logo';
import Button from '@src/components/Button';
import Dropdown from '@src/components/Dropdown';

import type { Admin } from '@src/types/models';
import type { IntlShape } from 'react-intl';
import type { Node } from 'react';

type Region = {
  id: string,
  slug: string,
  name: string,
};

type NavBarProps = {
  ...IntlShape,
  loading: boolean,
  admin?: Admin,
  regions: Array<Region>,
  handleLogout: () => void,
  handleUpdateRegion: (region_id: string) => void,
  children?: Node,
};

export default function NavBar(props: NavBarProps) {
  const { admin, regions, handleLogout, handleUpdateRegion, children } = props;
  let links;

  let actions = (
    <div className={cx(styles.actions)}>
      <Link to={'/login'}>
        <FormattedMessage
          id="NavBar.login"
          defaultMessage="Log In"
          description="Log In page link"
        />
      </Link>
    </div>
  );

  let mainContent = <div className="col-12">{children}</div>;

  if (admin) {
    actions = (
      <div className={cx(styles.actions)}>
        <span className={cx(styles.userName)}>{admin.email}</span>
        <Dropdown title={admin.region.name}>
          {regions
            .map(
              region =>
                admin.region.id !== region.id && (
                  <Button
                    key={region.id}
                    btnStyle="link"
                    className={cx(styles.regionLink)}
                    onClick={() => handleUpdateRegion(region.id)}
                  >
                    {region.name}
                  </Button>
                )
            )
            .filter(n => !!n)}
        </Dropdown>
        <Button btnStyle="link" onClick={handleLogout}>
          <FormattedMessage
            id="NavBar.logout"
            defaultMessage="Log Out"
            description="Log Out button"
          />
        </Button>
      </div>
    );

    links = (
      <div className={cx(styles.links)}>
        <p>
          <FormattedMessage
            id="NavBar.sectionCleaningServices"
            defaultMessage="Limpieza"
            description="NavBar section title"
          />
        </p>
        <Link to={'/cleaning_subscription'}>
          <FormattedMessage
            id="NavBar.listCleaningSubscription"
            defaultMessage="Suscripciones"
            description="NavBar ListCleaningSubscription menu item"
          />
        </Link>
        <Link to={'/punctuality'}>
          <FormattedMessage
            id="NavBar.listPunctuality"
            defaultMessage="Puntualidad"
            description="NavBar ListPunctuality menu item"
          />
        </Link>
        <Link to={'/operator'}>
          <FormattedMessage
            id="NavBar.listOperator"
            defaultMessage="Operadores"
            description="NavBar ListOperator menu item"
          />
        </Link>
        <Link to={'/recurrence'}>
          <FormattedMessage
            id="NavBar.listRecurrence"
            defaultMessage="Recurrencias"
            description="NavBar ListRecurrence menu item"
          />
        </Link>
        <Link to={'/service'}>
          <FormattedMessage
            id="NavBar.listService"
            defaultMessage="Servicios"
            description="NavBar ListService menu item"
          />
        </Link>
        <Link to={'/task'}>
          <FormattedMessage
            id="NavBar.listTask"
            defaultMessage="Solicitudes"
            description="NavBar ListTask menu item"
          />
        </Link>
        <Link to={'/feedback'}>
          <FormattedMessage
            id="NavBar.listFeedback"
            defaultMessage="Feedback emocional"
            description="NavBar ListFeedback menu item"
          />
        </Link>
        <p>
          <FormattedMessage
            id="NavBar.sectionOrders"
            defaultMessage="Órdenes"
            description="NavBar section title"
          />
        </p>
        <Link to={'/order'}>
          <FormattedMessage
            id="NavBar.listOrders"
            defaultMessage="Órdenes"
            description="NavBar ListOrders menu item"
          />
        </Link>
        <p>
          <FormattedMessage
            id="NavBar.sectionClients"
            defaultMessage="Clientes"
            description="NavBar section title"
          />
        </p>
        <Link to={'/office'}>
          <FormattedMessage
            id="NavBar.listOffices"
            defaultMessage="Ubicaciones"
            description="NavBar Clients menu item"
          />
        </Link>
        <p>
          <FormattedMessage
            id="NavBar.sectionFinance"
            defaultMessage="Finanzas"
            description="NavBar section title"
          />
        </p>
        <Link to={'/invoice'}>
          <FormattedMessage
            id="NavBar.listInvoices"
            defaultMessage="Facturas"
            description="NavBar Invoice menu item"
          />
        </Link>
        <p>
          <FormattedMessage
            id="NavBar.sectionTimeLines"
            defaultMessage="Time Line"
            description="NavBar section title"
          />
        </p>
        <Link to={'/timeline'}>
          <FormattedMessage
            id="NavBar.serivesTimeline"
            defaultMessage="Servicios"
            description="NavBar Services menu item"
          />
        </Link>
      </div>
    );

    mainContent = (
      <Fragment>
        <aside className={cx('col-2', styles.sidebar)}>{links}</aside>
        <div className="col-10 offset-2">{children}</div>;
      </Fragment>
    );
  }

  return (
    <div>
      <nav id="nav" className={cx(styles.nav)}>
        <div className={cx('container-fluid', styles.container)}>
          <div className={cx(styles.navBarLeft)}>
            <div className={cx(styles.header)}>
              <Link to="/" href="/">
                <Logo className={cx(styles.logo)} />
              </Link>
            </div>
          </div>

          {actions}
        </div>
      </nav>
      <div className={cx('container-fluid', styles.appContainer)}>
        <div className="row">{mainContent}</div>
      </div>
    </div>
  );
}
