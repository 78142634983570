// @flow
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { reduxForm } from 'redux-form';
import compose from 'lodash/flowRight';

import messages from './messages';
import Button from '@src/components/Button';
import Form from '@src/components/Form';
import FormGroupInputField from '@src/components/FormGroupInputField';
import { isRequired } from '@src/utils/validation';

import type { IntlShape } from 'react-intl';
import type { Service } from '@src/pages/resources/service/view/ViewServiceDetails';

type CancelServiceModalProps = {
  ...IntlShape,
  service: Service,
  handleSubmit: () => void,
};

export function CancelServiceModal(props: CancelServiceModalProps) {
  const { handleSubmit, intl: { formatMessage }, service } = props;

  return (
    <Form className="row justify-content-center" onSubmit={handleSubmit}>
      <div className="col-sm-10">
        <h3>
          <FormattedMessage
            id="CancelServiceModal.title"
            defaultMessage="Cancelar Servicio #{id}"
            description="CancelServiceModal title"
            values={{
              id: service.id,
            }}
          />
        </h3>
      </div>
      <div className="col-sm-10">
        <FormGroupInputField
          name="cancelation_reason"
          label={formatMessage(messages.cancelationReasonLabel)}
          size="sm"
          type="text"
          validate={[isRequired]}
        />
        <Button
          type="submit"
          size="sm"
          btnStyle="danger"
          onClick={handleSubmit}
        >
          <FormattedMessage
            id="CancelServiceModal.cancelButton"
            defaultMessage="Cancelar"
            description="CancelServiceModal cancel Button"
          />
        </Button>
      </div>
    </Form>
  );
}

export default compose(
  injectIntl,
  reduxForm({
    form: 'cancelServiceForm',
  })
)(CancelServiceModal);
