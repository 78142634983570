// @flow
import * as React from 'react';
import { Field } from 'redux-form';

import Input from '@src/components/Input';
import FormGroup from '@src/components/FormGroup';
import isInputInvalid from '@src/utils/isInputInvalid';

import type { InputProps } from '@src/components/Input';

export type FormGroupInputFieldProps = InputProps & {
  name: string,
  label?: *,
  type?: string,
  placeholder?: string,
  disabled?: boolean,
};

type RFFormGroupInputProps = FormGroupInputFieldProps & {
  input: *,
  meta: {
    active: boolean,
    invalid: boolean,
    touched: boolean,
    form: string,
    error?: string,
  },
};

export function RFFormGroupInput(
  props: RFFormGroupInputProps
): React.Element<*> {
  const {
    className,
    label,
    size,
    meta,
    input,
    type,
    placeholder,
    disabled,
  } = props;
  const error = (isInputInvalid(meta) && meta.error) || '';
  const htmlFor = `${meta.form}-${input.name || Math.random()}`;

  return (
    <FormGroup
      className={className}
      label={label}
      error={error}
      htmlFor={htmlFor}
    >
      <Input
        isInvalid={!!error}
        size={size}
        id={htmlFor}
        type={type}
        placeholder={placeholder}
        disabled={disabled}
        {...input}
      />
    </FormGroup>
  );
}

export default function FormGroupInputField(props: FormGroupInputFieldProps) {
  return <Field {...props} component={RFFormGroupInput} />;
}
